export function extractExistingParams(searchParams: URLSearchParams): Record<string, string[]> {
    const entries = Array.from(searchParams.entries());
    return entries.reduce<Record<string, string[]>>((acc, [key, value]) => {
      (acc[key] = acc[key] || []).push(value);
      return acc;
    }, {});
  }


  
export function PhoneUserInEgypt(phone: string) {
  return '+20' + phone
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CompareValues(values:any, data:any) {

  const NewValues = Object.keys(values).filter((key) => {
    if (typeof (data[key]) === "boolean") {
      return values[key] !== String(data[key])
    }
    return values[key] !== data[key]
  }).map((key) => {
    return { [key]: values[key] }
  })

  if (NewValues.length === 0) {
    return false
  } else {
    const mergedObject = Object.assign({}, ...NewValues);
    return mergedObject
  }
}




//--------- SLUG
export function getSlugFiy(input:string) {
    return input.trim().split(" ").join("-")
}
