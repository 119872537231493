import {useDispatch} from "react-redux";
import {AppDispatch} from "../store/store";
import {RequestPostCompare} from "../store/comparison/comparisonSlice";

function useCompare() {
    const dispatch = useDispatch<AppDispatch>();

    const handleCompare = (id: number, is_comparison: boolean) => {
        dispatch(RequestPostCompare(id));
    };
    return handleCompare;

}

export default useCompare;
