import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { theme } from "./utils/theme";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import { store } from "./store/store";
import CustomThemeProvider from "./components/layout/customThemeProvider";
import GoogleTagManager from "./components/common/googleTagManager";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Router>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <CustomThemeProvider>
          <App />
          <GoogleTagManager />
        </CustomThemeProvider>
      </Provider>
    </I18nextProvider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
