import React from 'react'
import ProfileLayout from "../../components/profile/profileLayout";
import HeroHeading from "../../components/common/HeroHeading";
import { Box, Typography } from "@mui/material";
import { t } from "i18next";

export default function TermsCondition() {
    const crumbs = [
        { label: ` ${t("Home")}`, link: "/", active: false },
        { label: `${t('terms_privacy')}`, link: `/profile/terms&condition`, active: true },
    ];
    return (
        <ProfileLayout crumbs={crumbs}>
            <HeroHeading heading={t('terms_privacy')} />

            <Box sx={{ fontSize: "18px", fontWeight: "600 !important", p: 2, wordSpacing: "2px", whiteSpace: "break-spaces", wordBreak: "break-all" }}>

                <Typography mb="16px" fontSize="18px" fontWeight={600}>
                    {t('textTerms1')}
                </Typography>
                <ol>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms2')}
                        </Typography>
                    </li>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms3')}
                        </Typography>
                    </li>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms4')}
                        </Typography>
                    </li>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms5')}
                        </Typography>
                    </li>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms6')}
                        </Typography>
                    </li>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms7')}
                        </Typography>
                    </li>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms8')}
                        </Typography>
                    </li>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms9')}
                        </Typography>
                    </li>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms10')}
                        </Typography>
                    </li>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textRefund11')}
                        </Typography>
                    </li>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms12')}
                        </Typography>
                    </li>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms13')}
                        </Typography>
                    </li>
                    <li>

                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms14')}
                        </Typography>
                    </li>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms15')}
                        </Typography>
                    </li>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms16')}
                        </Typography>
                    </li>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms17')}
                        </Typography>
                    </li>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms18')}
                        </Typography>
                    </li>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms19')}
                        </Typography>
                    </li>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms20')}
                        </Typography>
                    </li>
                    <li>
                        <Typography mb="16px" fontSize="18px" fontWeight={500}>
                            {t('textTerms21')}
                        </Typography>
                    </li>
                </ol>

            </Box>
        </ProfileLayout>
    );
}
