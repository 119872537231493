import { Box, Container } from "@mui/material";

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import Footer from "../components/layout/footer";
import HeroHeading from "../components/common/HeroHeading";
import TableComparsion from "../components/comparsion/tableComparsion";
import BreadCrumbs from "../components/common/breadCrumbs";

import { AppDispatch } from "../store/store";
import { RequestGetCompare } from "../store/comparison/comparisonSlice";

import { t } from "i18next";

const Comparsion = () => {
  // ------- DISPATCH ---------
  const dispatch = useDispatch<AppDispatch>();

  // -------- CONSTANT --------
  const crumbs = [
    { label: t("Home"), link: "/", active: false },
    {
      label: t("Compare List"),
      link: "/comparsion",
      active: true,
    },
  ];

  // -------- ONCE CHANGE --------
  useEffect(() => {
    dispatch(RequestGetCompare());
  }, []);
  return (
    <Box>
      <Container>

        {/* --------- BREADCRUMBS --------- */}
        <Box mt="48px" mb="40px">
          <BreadCrumbs crumbs={crumbs} />
        </Box>

        {/* --------- HeroHeading --------- */}
        <HeroHeading heading={t("Compare List")} />

        {/* --------- TABLE COMPARSION --------- */}
        <Box mt="32px" mb="48px" sx={{ overflowX: "auto" }}>
          <TableComparsion />
        </Box>

      </Container>
      <Footer />
    </Box>
  );
};

export default Comparsion;
