import { Box, Slider, Typography } from "@mui/material";
import React from "react";
import { MainButton } from "../../style/style";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useSearchParams } from "react-router-dom";
import { extractExistingParams } from "../../utils/helper-function";

function valuetext(value: number) {
  return `${value}°C`;
}

const AccordionContent = () => {
  // STATE & PARAMS
  const [searchParams, setSearchParams] = useSearchParams();

  const [value, setValue] = React.useState<any>([
    searchParams.get("price__gte") || 0,
    searchParams.get("price__lte") || 9000,
  ]);

  //-------- SELECTOR
  const lang = useSelector((state: RootState) => state.global.mainLang);

  // ---------------------------------
  // --------global Fucntion -----------
  // ----------------- -----------

  // ------- handle Change price ------
  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  // ------- handle Change price ------
  const handleApplyPrice = () => {
    setSearchParams({
      ...extractExistingParams(searchParams),
      price__lte: value[1].toString(),
      price__gte: value[0].toString(),
    });
  };

  return (
    <Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "300",
              order: lang === "en" ? 1 : 0,
              background: "#fff",
              padding: "5px",
              borderRadius: "6px",
            }}
          >
            <input
              style={{
                border: "none",
                width: "50px",
                background: "transparent",
                textAlign: "center",
              }}
              className="input_focused"
              value={value[1]}
              type={"number"}
              onChange={(e: any) =>
                handleChange(e, [value[0], +e.target.value])
              }
            />
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "300",
              background: "#fff",
              padding: "5px",
              textAlign: "center",
              borderRadius: "6px",
            }}
          >
            <input
              style={{
                width: "50px",
                background: "transparent",
                textAlign: "center",
                border: "none",
              }}
              className="input_focused"
              value={value[0]}
              type={"number"}
              onChange={(e: any) =>
                handleChange(e, [+e.target.value, value[1]])
              }
            />
          </Typography>
        </Box>

        <Box mt="30px">
          <Slider
            getAriaLabel={() => "Temperature range"}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            step={10}
            min={0}
            max={9000}
          />
        </Box>

        <MainButton
          sx={{ padding: "5px !important", mt: "20px", mb: "20px" }}
          onClick={() => {
            handleApplyPrice();
          }}
        >
          {t("apply")}
        </MainButton>
      </Box>
    </Box>
  );
};

export default AccordionContent;
