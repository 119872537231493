// import { BASEURL } from '@/data/APIS';
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice, current } from "@reduxjs/toolkit";
import { BASEURL } from "../../data/apis";
import customFetch from "../../utils/axios";
import { ProductProps } from "../../types/product";
import Cookies from "js-cookie";
import { stat } from "fs";

import { toast } from "react-toastify";

export interface ProductState {
  loading: boolean;
  error: boolean;
  product: ProductProps;
  errorMsg: String;
}

const initialState: ProductState = {
  loading: false,
  product: {},
  error: false,
  errorMsg: "",
};

export const RequestGetProduct = createAsyncThunk(
  "RequestGetProduct",
  async (id: any, ThunkApi) => {
    const state: any = ThunkApi.getState();
    const token = state.user.user;
    try {
      if (token) {
        const response = await axios.get(`${BASEURL}/products/${id}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        return response.data;
      } else {
        const response = await axios.get(`${BASEURL}/products/${id}/`);
        return response.data;
      }
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.message);
    }
  }
);

interface idanddata {
  id: number;
  data: any;
}
export const RequestPostReview = createAsyncThunk(
  "RequestPostReview",
  async ({ id, data }: idanddata, ThunkApi) => {
    const state: any = ThunkApi.getState();
    const token: any = state.user.user;
    try {
      const formData = new FormData();
      Object.keys(data).map((ele) => formData.append(ele, data[ele]));

      const response = await axios.post(
        `${BASEURL}/products/${id}/reviews/`,
        formData,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      await ThunkApi.dispatch({
        type: "product/set",
        payload: { user_has_reviewed: true },
      });

      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.data.detail);
    }
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    set: (state, action) => {
      state.product = { ...state.product, ...action?.payload };
    },
  },
  extraReducers: (builder) => {
    // GET Product
    builder
      .addCase(RequestGetProduct.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.product = {};
      })
      .addCase(RequestGetProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.product = action.payload;
      })
      .addCase(RequestGetProduct.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(RequestPostReview.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(RequestPostReview.fulfilled, (state, action) => {
        state.loading = false;
        state.product = {
          ...current(state.product),
          product_reviews: [
            ...current<any>(state?.product?.product_reviews),
            action.payload,
          ],
        };
      })
      .addCase(RequestPostReview.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload)
      });
  },
});

// Action creators are generated for each case reducer function

export default productSlice.reducer;
