import { BASEURL } from "../../data/apis";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export interface ProductsState {
  loading: boolean;
  error: boolean;
  products: any;
  errorMsg: String;
  filter: filterData;
  cate: any;
  breadCate: string;
  cateLoading: boolean
}

const initialState: ProductsState = {
  loading: false,
  products: [],
  error: false,
  errorMsg: "",
  cate: [],
  cateLoading: false,
  breadCate: localStorage.getItem("lang") === "ar" ? "الكل" : "All",
  filter: {
    color_ids: [],
    search: "",
    price__gte: "",
    price__lte: "",
    video_review: "",
    category_ids: [],
    rating: [],
    limit: "10",
    page: "1",
    ordering: "-price",
    avg_rating: []
  },
};

export interface filterData {
  category_ids?: Array<number>;
  video_review?: string;
  search?: string;
  color_ids?: Array<number>;
  price__gte?: string;
  price__lte?: string;
  rating: Array<number>;
  avg_rating: Array<number>;
  [key: string]: any;
  ordering?: string,
}

// -------- FETCH PRODUCT --------
export const RequestGetProductsTest = createAsyncThunk(
  "RequestGetProductsTest",
  async (filterProduct: any, ThunkApi) => {
    const query = new URLSearchParams(filterProduct).toString();

    const state: any = ThunkApi.getState();
    const token = state.user.user;

    const config = {
      headers: {
        Authorization: `Token ${token}`,
      }
    }

    try {
      if (token) {
        const response = await axios.get(`${BASEURL}/products/?${query}`, {...config });
        return response.data;
      } else {
        const response = await axios.get(`${BASEURL}/products/?${query}`);
        return response.data;
      }
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.message);
    }
  }
);

// -------- FETCH CATEGORY --------
export const RequestGetCategories = createAsyncThunk(
  "RequestGetCategories",
  async (_, ThunkApi) => {
    try {
      const response = await axios.get(`${BASEURL}/category/`);
      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.data);
    }
  }
);

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setCategoryID: (state, action) => {
      state.filter.category_ids = action.payload
    },
    setSearch : (state, action) => {
      state.filter.page = "1"
      state.filter.search = action.payload
    },
    setBread: (state, action) => {
      state.breadCate = action.payload
    },
  },
  extraReducers: (builder) => {
    // ---------- REQUEST GET CATEGORIES ----------
    builder
      .addCase(RequestGetCategories.pending, (state) => {
        state.loading = true;
        state.cateLoading = true;
        state.error = false;
        state.cate = [];
      })
      .addCase(RequestGetCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.cateLoading = false;
        state.cate = action.payload;
      })
      .addCase(RequestGetCategories.rejected, (state, action: any) => {
        state.loading = false;
        state.error = false;
        state.cateLoading = false;
      });

      // ---------- REQUEST GET PRODUCTS ----------
    builder
      .addCase(RequestGetProductsTest.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.products = [];
      })
      .addCase(RequestGetProductsTest.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(RequestGetProductsTest.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearch, setBread} = productsSlice.actions;

export default productsSlice.reducer;
