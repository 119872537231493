import { Typography, Box } from "@mui/material";
import { useLottie } from "lottie-react";
import groovyWalkAnimation from "../assets/payment/animate-2.json";
import { MainButton } from "../style/style";
import { useNavigate } from "react-router-dom";
const PaymentFail = () => {
  const options = {
    animationData: groovyWalkAnimation,
    loop: true,
  };

  const { View } = useLottie(options);
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        flex: "1",
        textAlign: "center",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Box sx={{ width: "300px", margin: "auto" }}>{View}</Box>
        <Typography>Your Order Has Been Faild</Typography>
        <Typography>
          Your items has been Deleted, make it again 
        </Typography>
      </Box>
      <Box sx={{ mt: "40px" }}>
        <MainButton onClick={() => navigate("/profile/order")}>
          continue to orders
        </MainButton>{" "}
        <br />
        <button
          onClick={() => navigate("/")}
          className="main-text"
          style={{ marginTop: "15px", outline: "none", border: "none", cursor:"pointer" }}
        >
          Back to home
        </button>
      </Box>
    </Box>
  );
};

export default PaymentFail;
