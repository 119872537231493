import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../data/apis";

export interface RecommendedState {
  value: number;
  loading: boolean;
  recommended: any;
  likeLoading: boolean
  like: any
}

const initialState: RecommendedState = {
  value: 0,
  loading: false,
  recommended: [],
  likeLoading: false,
  like: []
};

// REQUEST Recommended For You
export const RequestGetRecommended = createAsyncThunk(
  "RequestGetRecommended",
  async (url: any, ThunkApi) => {
    const state: any = ThunkApi.getState();
    const token = state.user.user;
    try {
      if (token) {
        const response = await axios.get(`${BASEURL}${url}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        return response.data;
      } else {
        const response = await axios.get(`${BASEURL}${url}`);
        return response.data;
      }
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.data);
    }
  }
);

// REQUEST May Like You
export const RequestGetLike = createAsyncThunk(
  "RequestGetLike",
  async (url: any, ThunkApi) => {
    const state: any = ThunkApi.getState();
    const token = state.user.user;
    try {
      if (token) {
        const response = await axios.get(`${BASEURL}${url}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        return response.data;
      } else {
        const response = await axios.get(`${BASEURL}${url}`);
        return response.data;
      }
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.data);
    }
  }
);

export const recommendedSlice = createSlice({
  name: "recommended",
  initialState,
  reducers: {
    setRecommended: (state, action) => {
      state.recommended = state?.recommended?.map((ele: any) =>
        ele.id === action?.payload?.id
          ? { ...ele, ...action?.payload?.product }
          : ele
      );
    },
    setLiked: (state, action) => {
      state.like = state?.like?.map((ele: any) =>
        ele.id === action?.payload?.id
          ? { ...ele, ...action?.payload?.product }
          : ele
      );
    },
  },
  extraReducers: (builder) => {
    // REQUEST Recommended For You
    builder
      .addCase(RequestGetRecommended.pending, (state) => {
        state.loading = true;
        state.recommended = [];
      })
      .addCase(RequestGetRecommended.fulfilled, (state, action) => {
        state.loading = false;
        state.recommended = action.payload;
      })
      .addCase(RequestGetRecommended.rejected, (state, action: any) => {
        state.loading = false;
      });
      // REQUEST May Like You
      builder
      .addCase(RequestGetLike.pending, (state) => {
        state.likeLoading = true;
        state.like = [];
      })
      .addCase(RequestGetLike.fulfilled, (state, action) => {
        state.likeLoading = false;
        state.like = action.payload;
      })
      .addCase(RequestGetLike.rejected, (state, action: any) => {
        state.likeLoading = false;
      });
  },
});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = recommendedSlice.actions

export default recommendedSlice.reducer;
