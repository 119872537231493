import { Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { extractExistingParams } from "../../utils/helper-function";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";


const BasicPagination = ({ products }: { products?: any; }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePageChange = (page: number) => {
    setSearchParams({ ...extractExistingParams(searchParams), page: page.toString() });
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  
  useEffect(() => {
    scrollToTop();
  }, [searchParams]);
  

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mb: "50px",
        "& .MuiPaginationItem-root": {
          borderRadius: "4px",
          border: "1px solid #F5841F",
        },
        "& .Mui-selected": {
          borderBottom: "1px solid #000",
          background: "#fff !important",
          border: "1px solid #F5841F !important",
          color: "#F5841F !important",
        },
        "& .MuiPaginationItem-outlined": {
          color: "#000",
          border: "1px solid #DFE3E8",
          borderRadius: "4px !important"
        },
        "& .MuiPaginationItem-previousNext": {
          background: "#fff",
          color: "#000",
          borderRadius: "8px",
        },
        "& .MuiPaginationItem-previousNext.Mui-disabled": {
          background: "#919EAB",
          color: "#C4CDD5"

        },
      }}
    >
      <Pagination
        count={Math.ceil(products.count / 10)}
        page={Number(searchParams.get("page")) || 1}
        variant="outlined"

        onChange={(e, page) => {
          handlePageChange(page)
        }}
      />
    </Box>
  );
};

export default BasicPagination;
