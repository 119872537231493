import { Box } from "@mui/material";

import CustomCheck from "../common/customCheck";
import { filterData } from "../../store/products/productsSlice";


interface AccordionCheckState {
  items?: any;
  type?: string;
  filter?: filterData;
}

const AccordionCheck = ({ items, type, filter }: AccordionCheckState) => {

  return (
    <Box sx={{mb:"20px"}}>
      {items?.map((item: any) => {
        return (
          <CustomCheck
            id={item.id}
            name={item.product_color ? item.product_color : item?.name}
            key={item?.id}
            filter={filter}
            type={type}
          />
        );
      })}
    </Box>
  );
};

export default AccordionCheck;

