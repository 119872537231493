// import { BASEURL } from '@/data/APIS';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { BASEURL } from "../../data/apis";


export interface OfferState {
  loading: boolean;
  error: boolean;
  ads: any;
  errorMsg: String;
}

const initialState: OfferState = {
  loading: false,
  ads: [],
  error: false,
  errorMsg: "",
};

export const RequestGetAds = createAsyncThunk(
  "RequestGetads",
  async (_, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASEURL}/Ads/`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const offerSlice = createSlice({
  name: "ads",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(RequestGetAds.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.ads = [];
      })
      .addCase(RequestGetAds.fulfilled, (state, action) => {
        state.loading = false;
        state.ads = action.payload;
      })
      .addCase(RequestGetAds.rejected, (state, action: any) => {
        state.loading = false;
        state.error = false;
      });
  },
});

// Action creators are generated for each case reducer function

export default offerSlice.reducer;
