import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

import {BASEURL} from "../../data/apis";
import {toast} from "react-toastify";
import {t} from "i18next";


export interface WishListState {
    loading: boolean;
    error: boolean;
    wishlists: any;
    errorMsg: String;
}

const initialState: any = {
    loading: false, wishlists: [], error: false, errorMsg: "",
};

export const RequestWishList = createAsyncThunk("RequestWishList", async (id: any, ThunkApi) => {
    // let dispatch=useDispatch<AppDispatch>()
    const state: any = ThunkApi.getState();
    const token: any = state.user.user;
    const product: any = state.product;


    try {
        const response = await axios.post(`${BASEURL}/products/${id}/wishlist/`, {}, {headers: {Authorization: `Token ${token}`}});
        return response.data;
    } catch (error: any) {

        return ThunkApi.rejectWithValue(error.data);
    }
});
export const RequestGetWishList = createAsyncThunk("RequestGetWishList", async (_, ThunkApi) => {
    const state: any = ThunkApi.getState();
    const token: any = state.user.user;
    try {
        const response = await axios.get(`${BASEURL}/wishlist/`, {
            headers: {Authorization: `Token ${token}`},
        });


        return response.data;
    } catch (error: any) {
        return ThunkApi.rejectWithValue(error.data);
    }
});


export const WishListSlice = createSlice({
    name: "wishlists", initialState, reducers: {}, extraReducers: (builder) => {
        builder
            .addCase(RequestWishList.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(RequestWishList.fulfilled, (state, action) => {

                state.loading = false;
                state.product = action.payload;

                if (action.payload?.message === "deleted") {
                    toast.error(t('wishlist_deleted'))
                } else {
                    toast.success(t('wishlist_done'))
                }

                state.error = false;
            })
            .addCase(RequestWishList.rejected, (state, action: any) => {
                state.loading = false;
                state.error = true;
            });

        // GET wishlist
        builder
            .addCase(RequestGetWishList.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(RequestGetWishList.fulfilled, (state, action) => {
                state.loading = false;
                state.wishlists = action.payload;
                state.error = false;
            })
            .addCase(RequestGetWishList.rejected, (state, action: any) => {
                state.loading = false;
                state.error = true;
            });

    },
});

// Action creators are generated for each case reducer function

export default WishListSlice.reducer;
