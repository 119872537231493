import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import type { PayloadAction } from "@reduxjs/toolkit";

import { BASEURL } from "../../data/apis";
import customFetch from "../../utils/axios";
import { ProductProps } from "../../types/product";
import { toast } from "react-toastify";
import { t } from "i18next";

export interface ReviewState {
  loading: boolean;
  error: boolean;
  product: ProductProps;
  errorMsg: String;
  isAddReview: boolean;
}

const initialState: ReviewState = {
  loading: false,
  product: {},
  error: false,
  errorMsg: "",
  isAddReview: false
};
interface idanddata {
  id: number;
  data: any;
}
export const RequestGetReview = createAsyncThunk(
  "RequestGetReview",
  async ({ id, data }: idanddata, ThunkApi) => {
    const state: any = ThunkApi.getState();
    const token: any = state.user.user;
    try {
      const formData = new FormData();
      Object.keys(data).map((ele) => formData.append(ele, data[ele]));

      const response = await axios.post(
        `${BASEURL}/products/${id}/reviews/`,
        formData,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.message);
    }
  }
);

export const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    setIsAddReview: (state, action) => {
      state.isAddReview = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(RequestGetReview.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.product = {};
      })
      .addCase(RequestGetReview.fulfilled, (state, action) => {
        state.loading = false;
        state.product = action.payload;
        toast.success(t("reviewed_done"));
      })
      .addCase(RequestGetReview.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload.message;
        toast.error(action.payload.message);
      });
  },
});

// Action creators are generated for each case reducer function
export const { setIsAddReview} = reviewSlice.actions;

export default reviewSlice.reducer;
