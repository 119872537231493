// import { BASEURL } from '@/data/APIS';
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

import {BASEURL} from "../../data/apis";

export interface OfferState {
    loading: boolean;
    error: boolean;
    offer: any;
    errorMsg: String;
}

const initialState: OfferState = {
    loading: false,
    offer: [],
    error: false,
    errorMsg: "",
};

export const RequestGetOffer = createAsyncThunk(
    "RequestGetOffer",
    async (_, {getState, rejectWithValue}) => {
        try {
            const response = await axios.get(`${BASEURL}/products/?is_offer=true`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.data);
        }
    }
);

export const RequestGetUserOffer = createAsyncThunk(
    "RequestGetUserOffer",
    async (id: number, {getState, rejectWithValue}) => {
        const state: any = getState();
        const token = state.user.user;
        try {
            const response = await axios.get(`${BASEURL}/products/?is_offer=true`, {
                headers: {
                    Authorization: `Token ${token}`
                }
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.data);
        }
    }
);

export const offerSlice = createSlice({
    name: "offer",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(RequestGetOffer.pending, (state) => {
                state.loading = true;
                state.error = false;
                state.offer = [];
            })
            .addCase(RequestGetOffer.fulfilled, (state, action) => {
                state.loading = false;
                state.offer = action.payload;
            })
            .addCase(RequestGetOffer.rejected, (state, action: any) => {
                state.loading = false;
                state.error = false;
            });

        builder
            .addCase(RequestGetUserOffer.pending, (state) => {
                state.loading = true;
                state.error = false;
                state.offer = [];
            })
            .addCase(RequestGetUserOffer.fulfilled, (state, action) => {
                state.loading = false;
                state.offer = action.payload;
            })
            .addCase(RequestGetUserOffer.rejected, (state, action: any) => {
                state.loading = false;
                state.error = false;
            });
    },
});

// Action creators are generated for each case reducer function

export default offerSlice.reducer;
