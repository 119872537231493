import { Box, Container, Grid } from "@mui/material";
import { useEffect } from "react";
import Footer from "../components/layout/footer";
import ProductCard from "../components/common/productCard";
import EmptyItems from "../components/common/emptyItems";
import HeroHeading from "../components/common/HeroHeading";
import BreadCrumbs from "../components/common/breadCrumbs";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store/store";
import { RequestGetWishList, } from "../store/wishlist/wishlistSlice";
import savedEmpty from '../assets/common/savedEmpty.png'

const SavedItems = () => {
    // -------- SELECTORS --------
    let result = useSelector((state: any) => state.wishlists.wishlists);
    let loading = useSelector((state: any) => state.wishlists.loading);

    // -------- DISPATCH --------
    const dispatch = useDispatch<AppDispatch>();

    // -------- CONSTANTS --------
    const crumbs = [{ label: t("Home"), link: "/", active: false }, {
        label: t("saved items"),
        link: "/saved",
        active: true
    }];

    // -------- ONCE CHANGE --------
    useEffect(() => {
        dispatch(RequestGetWishList());
    }, []);
    
    return (
        <Box>
            <Container>
                {/* ------- BreadCrumbs------- */}
                <Box mt="48px" mb="40px">
                    <BreadCrumbs crumbs={crumbs} />
                </Box>

                {/* ------- HeroHeading ------- */}
                <HeroHeading heading={t("your saved items")} />

                {/* ------- PRODUCTS ------- */}
                <Grid container spacing={3}>
                    {result?.at(0)?.product_wishlist?.map((product: any) => (<Grid item xs={12} sm={6} md={4} lg={3}>
                        <ProductCard type="new" product={product} />
                    </Grid>))}
                </Grid>

                {/* <EmptyItems /> */}
                {result?.at(0)?.product_wishlist?.length === 0 && <EmptyItems image={savedEmpty} loading={loading} />}
            </Container>
            <Footer />
        </Box>);
};

export default SavedItems;
