import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import photoSaved from "../../assets/common/1.png";
import { t } from "i18next";

interface EmptyItemsProps {
  image?: string;
  loading?: boolean;
}

const EmptyItems = ({ image = photoSaved, loading = false}: EmptyItemsProps) => {
  const [isDelayed, setIsDelayed] = useState(false);

  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      setIsDelayed(true);
    }, 500); // 1000 milliseconds or 1 second

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(delayTimeout);
  }, []);
  return (
    <>
      {loading === false && (
        <Box>
          <Typography sx={{mt:"32px", fontSize:"14px", fontWeight:"400", color:"#1B1B1B"}}>{t('result_empty')}</Typography>
          <Box
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={image} alt="saved" />
        </Box>
        </Box>
      )}
    </>
  );
};

export default EmptyItems;
