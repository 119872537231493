import { Box, Stack } from "@mui/material";

import { CategoriesState } from "../../types/categories";

const ServiceItem = ({ cate }: {cate:CategoriesState}) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          background: "#F2F2F2",
          overflow: "hidden",
          height: "148px",
          direction: "ltr",
          borderRadius:"8px"
        }}
      >
        <Stack
          direction="row"
          sx={{
            width: "100%",
            position: "relative",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box
            sx={{
              padding: "10px 32px",
              flex: "1",
              alignSelf: "center",
              justifyContent: "start",
              textAlign: "left",
              fontWeight: "600",
              zIndex: "1",
            }}
            className="font-hvr"
          >
            {cate.name}
          </Box>
          <Box sx={{ position: "relative", zIndex: "1", width:"126px" }}>
            <img src={cate.cat_image} alt="services-1" width={"100%"} />
          </Box>
          <Box
            sx={{
              position: "absolute",
              left: "0",
              top: "0",
              width: "100%",
              height: "100%",
            }}
          >
            <img
              src={cate.wall_image}
              alt="mask"
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default ServiceItem;
