import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { colors } from "../../utils/theme";
import AmountQty from "../productDetails/amountQty";
import Box from "@mui/material/Box/Box";

import { t } from "i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Link } from "react-router-dom";
import { closeCartIcon } from "../../assets/svg/svg";
import useRemoveItem from "../../hooks/useRemoveItem";


export default function TableCart({ items }: any) {
  // ------- SELECTOR -------
  const lang = useSelector((state: RootState) => state.global.mainLang);
  // ------- COLORS -------
  const { blue } = colors;
  // ------- HOOKS -------
  const handleRemoveItem = useRemoveItem();

  return (
    <TableContainer component={Paper} sx={{ boxShadow: "0" }}>
      {/* TODO: MAKE IT DATATABLE OR SIMPLIFY IT */}
      <Table sx={{ border: "1px solid #B9B9B9" }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{background:"#FBFBFB"}}>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderRight: "1px solid rgba(185, 185, 185, 1) !important",
                },
                textAlign: "center",
                fontSize: "18px",
                fontWeight:"600"
              }}
            >
              {t("item")}
            </TableCell>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderRight: "1px solid rgba(185, 185, 185, 1) !important",
                },
                fontWeight:"600",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              {t("title")}
            </TableCell>
            <TableCell
              sx={{
                textAlign: "center",
                "&.MuiTableCell-root": {
                  borderRight: "1px solid rgba(185, 185, 185, 1) !important",
                },
                fontWeight:"600",
                fontSize: "18px",
              }}
            >
              {t("quantity")}
            </TableCell>
            <TableCell
              sx={{
                "&.MuiTableCell-root": {
                  borderRight: "1px solid rgba(185, 185, 185, 1) !important",
                },
                textAlign: "center",
                fontWeight:"600",
                fontSize: "18px",
              }}
            >
              {t("price")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
          items?.map((row: any) => (
            <TableRow
              key={row?.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  width: "25%",
                  "&.MuiTableCell-root": {
                    borderRight: "1px solid rgba(185, 185, 185, 1) !important",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    className="test"
                    sx={{
                      color: "red",
                      fontSize: "36px",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemoveItem(row?.id)}
                  >
                    {closeCartIcon}
                  </Box>
                  <Box sx={{width:"214px", height:"208px"}}>
                    <Link to={`/shop/${row?.product?.id}`}>
                      <img src={row?.image} alt="test" width='214px' height='208px'/>
                    </Link>
                  </Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  width: "25%",
                  "&.MuiTableCell-root": {
                    borderRight: "1px solid rgba(185, 185, 185, 1) !important",
                  },
                  fontWeight: "600",
                  fontSize: "18px",
                  textAlign:"center"
                }}
              >
                {row?.product?.name}
              </TableCell>
              <TableCell
                sx={{
                  width: "25%",
                  "&.MuiTableCell-root": {
                    borderRight: "1px solid rgba(185, 185, 185, 1) !important",
                  },
                }}
              >
                <AmountQty quantity={row.quantity} id={row?.product?.id} productQty={row?.product?.quantity}/>
              </TableCell>
              <TableCell
                sx={{
                  width: "25%",
                  "&.MuiTableCell-root": {
                    borderRight: "1px solid rgba(185, 185, 185, 1) !important",
                  },
                  color: blue[100],
                  fontWeight: "600",
                  fontSize: "18px",
                  textAlign: lang === "en" ? "center" : "center",
                }}
              >
                {row?.product?.price} {t('egp')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
