import { Box, Typography } from "@mui/material";
import { useState } from "react";

interface AccordionProps {
    text?:string;
    content?:any
}


const Accordion = ({ text, content }: AccordionProps) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <Box sx={{padding:"0 16px"}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 0",
        }}
      >
        <Typography sx={{ fontWeight: "600" }}>{text}</Typography>
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "18px",
            cursor: "pointer",
          }}
          onClick={() => setIsActive((old) => !old)}
        >
          {isActive ? "-" : "+"}
        </Typography>
      </Box>
      {isActive && content}
    </Box>
  );
};

export default Accordion;
