import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoute = () => {
  const token = useSelector((state: RootState) => state.user.user)
  return token ? <Outlet /> : <Navigate to="/" />

}

export default PrivateRoute