import React from 'react';
import TagManager from 'react-gtm-module';

const GoogleTagManager = () => {
  React.useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-NMFHRDR2', // Replace with your GTM container ID
    });
  }, []);

  return null; // or you can return a loading indicator or an empty div
};

export default GoogleTagManager;