import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import i18next, { t } from "i18next";


export default function BasicTable({ tech }: any) {
  return (
    <TableContainer component={Paper} sx={{ boxShadow: "0" }}>
      {/* TODO: DATA TABLE */}
      <Table sx={{ border: "1px solid #B9B9B9" }} aria-label="simple table">
        <TableHead>
          {tech?.material !== null && (
            <TableRow 
            sx={{
              ".css-1vsbni-MuiTableCell-root": {
                textAlign:
                  i18next.language === "en" ? "left" : "right !important",
              },
              ".css-1aybez3-MuiTableCell-root": {
                textAlign:
                  i18next.language === "en" ? "left" : "right !important",
              },
            }}
            >
              <TableCell
                sx={{
                  background: "#FAC29033",
                  "&.MuiTableCell-root": {
                    borderRight: "1px solid rgba(185, 185, 185, 1) !important",
                    textAlign:
                      i18next.language === "en" ? "left" : "right !important",
                      borderLeft: "1px solid rgba(185, 185, 185, 1)!important",
                  },
                }}
              >
                {t("material")}
              </TableCell>
              <TableCell
                sx={{
                  "&.MuiTableCell-root" : {
                    textAlign:
                    i18next.language === "en" ? "left" : "right !important",
                  }

                }}
              >
                {tech?.material}
              </TableCell>
            </TableRow>
          )}
        </TableHead>

        <TableBody
          sx={{
            ".css-1vsbni-MuiTableCell-root": {
              textAlign:
                i18next.language === "en" ? "left" : "right !important",
            },
            ".css-1aybez3-MuiTableCell-root": {
              textAlign:
                i18next.language === "en" ? "left" : "right !important",
            },
            "& .MuiTableCell-root": {
              borderRight: "1px solid rgba(185, 185, 185, 1) !important",
              textAlign:
                i18next.language === "en" ? "left" : "right !important",
            },
          }}
        >
          {tech?.package_dimensions !== null && (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  background: "#FAC29033",
                  width: "50%",
                  "&.MuiTableCell-root": {
                    borderRight: "1px solid rgba(185, 185, 185, 1) !important",
                  },
                }}
              >
                {t("packagedimensions")}
              </TableCell>
              <TableCell
                sx={{
                  ".css-1vsbni-MuiTableCell-root": {
                    textAlign:
                      i18next.language === "en" ? "left" : "right !important",
                  },
                }}
              >
                {tech?.package_dimensions}
              </TableCell>
            </TableRow>
          )}

          {tech?.kilograms_capacity !== null && (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  background: "#FAC29033",
                  width: "50%",
                  "&.MuiTableCell-root": {
                    borderRight: "1px solid rgba(185, 185, 185, 1) !important",
                  },
                }}
              >
                {t("kilogramscapacity")}
              </TableCell>
              <TableCell>{tech?.kilograms_capacity}</TableCell>
            </TableRow>
          )}

          {tech?.number_of_excitations !== null && (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  background: "#FAC29033",
                  width: "50%",
                  "&.MuiTableCell-root": {
                    borderRight: "1px solid rgba(185, 185, 185, 1) !important",
                  },
                }}
              >
                {t("numberofexcitations")}
              </TableCell>
              <TableCell>{tech?.number_of_excitations}</TableCell>
            </TableRow>
          )}

          {tech?.special_features !== "" && (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  background: "#FAC29033",
                  width: "50%",
                  "&.MuiTableCell-root": {
                    borderRight: "1px solid rgba(185, 185, 185, 1) !important",
                  },
                }}
              >
                {t("specialfeatures")}
              </TableCell>
              <TableCell>{tech?.special_features}</TableCell>
            </TableRow>
          )}

          {tech?.item_weight !== null && (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  background: "#FAC29033",
                  width: "50%",
                  "&.MuiTableCell-root": {
                    borderRight: "1px solid rgba(185, 185, 185, 1) !important",
                  },
                }}
              >
                {t("itemweight")}
              </TableCell>
              <TableCell>{tech?.item_weight}</TableCell>
            </TableRow>
          )}

          {tech?.date_first_available !== null && (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  background: "#FAC29033",
                  width: "50%",
                  "&.MuiTableCell-root": {
                    borderRight: "1px solid rgba(185, 185, 185, 1) !important",
                  },
                }}
              >
                {t("datefirstavailable")}
              </TableCell>
              <TableCell>{tech?.date_first_available}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
