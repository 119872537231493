import { useRef, useEffect, useState } from "react";
import { H2 } from "../../style/style";
import { Box, Skeleton } from "@mui/material";
import { colors } from "../../utils/theme";
import ProductCard from "../common/productCard";
import Slider from "react-slick";

import productImage from "../../assets/recommend/product-6.png";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { RequestGetRecommended } from "../../store/recommended/recommendedSlice";
import { t } from "i18next";
import { Link } from "react-router-dom";

interface RecommendedState {
  heading?: string;
  fetchUrl?: string;
}

const RecommendSection = ({
  heading = t("Recommended for you"),
  fetchUrl = "/recommended/"
}: RecommendedState) => {

  // ---------- DISPATCH ----------
  const dispatch = useDispatch<AppDispatch>();
  const { dark, blue } = colors;

  // ---------- SELECTOR ----------
  const { recommended, loading } = useSelector(
    (state: RootState) => state.recommended
  );


  // ---------- CONSTANT ----------
  const settings = {
    className: "serv",
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 2,
    swipeToSlide: true,
    speed: 1000,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
        },

      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },

      },
    ],
  };

  const arrowRef = useRef<Slider>(null);

  // Add state variable to track the current slide index
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  // ---------- HANDLERS ----------
  const handlePrevClick = (): void => {
    if (arrowRef.current !== null) {
      arrowRef.current.slickPrev();
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  const handleNextClick = (): void => {
    if (arrowRef.current !== null) {
      arrowRef.current.slickNext();
      setCurrentSlideIndex(currentSlideIndex + 1);
    }
  };

  // --------- ONCE CHNAGE ----------
  useEffect(() => {
    dispatch(RequestGetRecommended(fetchUrl));
  }, [fetchUrl]);
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} mb="24px" mt="48px">
        <H2 variant="h2" >
          {heading}
        </H2>

        <Link to={`/shop/?is_best_seller=true`} style={{ color: "#000", textDecoration: "none", fontWeight: "500" }}>
          {t("view_all")}
        </Link>
      </Box>

      <div style={{ position: "relative" }}>
        {loading ? (
          <Slider {...settings} ref={arrowRef}>
            {Array.from(new Array(12)).map((item, index) => {
              return (
                <Box key={index}>
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={250}
                    sx={{
                      textAlign: "center",
                      display: "block",
                      margin: "auto",
                    }}
                  />
                  <Skeleton variant="text" width="100%" />
                </Box>
              );
            })}
          </Slider>
        ) : (
          <Slider {...settings} ref={arrowRef}>
            {recommended?.map((product: any, index: number) => (
              <Box key={index}>
                <ProductCard type="" image={productImage} product={product} />
              </Box>
            ))}
          </Slider>
        )}

        <div className="slider-arrow">
          {currentSlideIndex > 0 && (
            <button onClick={handlePrevClick} className="back">
              <NavigateBeforeIcon />
            </button>
          )}
          <button onClick={() => handleNextClick()} className="next">
            <NavigateNextIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default RecommendSection;
