import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../data/apis";
import Cookies from "js-cookie";

import { toast } from "react-toastify";
import { t } from "i18next";
import { setOpenDrawerCart } from "../globalSlice";
async function fromURLToImage(url: string): Promise<File> {
    const fileName = "image.jpg";
    const response = await fetch(url);
    const type = response.headers.get("content-type") || "image/jpeg";
    const blob = await response.blob();
    const file = new File([blob], fileName, { type });
    return await file;
}
// async function fromURLToImage(url: string): Promise<File> {
//     const fileName = "image.jpg";
//     const response = await fetch(`/proxy-image?url=${encodeURIComponent(url)}`);
    
//     if (!response.ok) {
//       throw new Error(`Failed to fetch image: ${response.statusText}`);
//     }
  
//     const type = response.headers.get("content-type") || "image/jpeg";
//     const blob = await response.blob();
//     return new File([blob], fileName, { type });
//   }
interface TotalState {
    shipping_price?: number | null;
    total_products_price: number | null;
    delivery_total_price: number | null;

}

export interface GuestCartState {
    loading: boolean;
    items: any;
    all_items: any;
    cartID: any;
    mainCartId: any;
    cartUser: TotalState;
}

const initialState: GuestCartState = {
    loading: false,
    items: [],
    all_items: [],
    cartID: Cookies.get("sessionid") || null,
    mainCartId: Cookies.get("mainCartId") || null,
    cartUser: {
        shipping_price: 0,
        total_products_price: 0,
        delivery_total_price: 0,
    },
};

export const RequestGetGuestCart = createAsyncThunk(
    "RequestGetGuestCart",
    async (data, { getState, rejectWithValue }) => {
        const state: any = getState();
        const cartID = state.guestCart.cartID;
        const response = await axios.get(`${BASEURL}/cart/?session_key=${cartID}`);
        return response.data;
    }
);

export const RequestPostToGuestCart = createAsyncThunk(
    "RequestPostToGuestCart",
    async (data: any, { getState, rejectWithValue, dispatch }) => {
        console.log(data);
        const state: any = getState();
        const cartID = state.guestCart.cartID;
        Cookies.get()
        data["session_key"]=cartID
        try {
            const response = await axios.post(
                `${BASEURL}/cart/add-to-cart/${data.product_id}/`
                , data,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            dispatch(setOpenDrawerCart(true))
            // console.log(response.data?.items);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const RequestDeleteGuestItem = createAsyncThunk(
    "RequestDeleteGuestItem",
    async (id: any, { getState, rejectWithValue }) => {
        const state: any = getState();
        const cartID = state.guestCart.cartID;
        console.log(id);
        const response = await axios.delete(
            `${BASEURL}/cart/${Cookies.get("mainCartId")}/?session_key=${cartID}`,
            {
                data: {
                    "cartitem_id": id
                }
            }
        );
        return { res: response.data, id: id };
    }
);

export const RequestUpdateGuestItem = createAsyncThunk(
    "RequestUpdateGuestItem",
    async ({ quantity, id }: any, { getState, rejectWithValue }) => {
        const state: any = getState();
        const cartID = state.guestCart.cartID;
        const response = await axios.patch(
            `${BASEURL}/cart/${Cookies.get("mainCartId")}/?session_key=${cartID}`,
            {
                "product_id": id,
                "quantity": quantity
            }
        );
        return { res: response.data, quantity, id };
    }
);

export const guestCartSlice = createSlice({
    name: "guestCart",
    initialState,
    reducers: {
        removeCartID: (state, action) => {
            state.cartID = null;
            Cookies.remove("sessionid");
        },
        clearCart: (state, action) => {
            state.items = [];
            state.cartUser = {
                shipping_price: null,
                total_products_price: null,
                delivery_total_price: null,
            };
        },
    },
    extraReducers: (builder) => {
        // Request GET CART
        builder
            .addCase(RequestGetGuestCart.pending, (state) => {
                state.loading = true;
                state.items = [];
                state.all_items = [];
            })
            .addCase(RequestGetGuestCart.fulfilled, (state, action) => {
                state.loading = false;
                state.items = action.payload?.at(0)?.items || [];
                state.all_items = action.payload?.at(0) || [];

                // ------------- Add Item to Cookies -------------
                const Guest_Token: any = Cookies.get("sessionid");
                !Guest_Token && Cookies.set("sessionid", action.payload?.session_key, { expires: 7 });
                Cookies.set("mainCartId", action.payload?.at(0)?.id, { expires: 7 });

                let sumTotal = state?.items
                    ?.map((item: any) => item?.quantity * item?.product?.price)
                    .reduce((total: any, current: any) => total + current, 0);

                state.cartUser.total_products_price = sumTotal;
                state.cartUser.delivery_total_price =
                    sumTotal + state.cartUser.shipping_price;
            })
            .addCase(RequestGetGuestCart.rejected, (state, action: any) => {
                state.loading = false;
                state.items = [];
            });

        // ADD Items To Cart
        builder
            .addCase(RequestPostToGuestCart.pending, (state) => {
                state.loading = true;
            })
            .addCase(RequestPostToGuestCart.fulfilled, (state, action) => {
                state.loading = false;
                // ------------- Add Item to Cookies -------------
                const Guest_Token: any = Cookies.get("sessionid");
                !Guest_Token && Cookies.set("sessionid", action.payload?.session_key, { expires: 7 });
                Cookies.set("mainCartId", action.payload?.id, { expires: 7 });

                !Guest_Token && (state.cartID = action?.payload.session_key);


                // ----- update ITEMS ---
                state.mainCartId = action?.payload.id
                state.items = action.payload?.items;
                state.all_items = action.payload;

                // -- update price --
                state.cartUser.total_products_price = action?.payload?.res?.total_amount;
                state.cartUser.delivery_total_price =
                    action?.payload?.res?.total_amount_with_delivery;


                toast.success(t("added_item"));
            })
            .addCase(RequestPostToGuestCart.rejected, (state, action: any) => {
                state.loading = false;
                toast.error(action?.payload);
            });

        // Delete Item
        builder
            .addCase(RequestDeleteGuestItem.pending, (state) => {
                state.loading = true;
            })
            .addCase(RequestDeleteGuestItem.fulfilled, (state, action) => {
                state.loading = false;
                // delete item from cart --
                state.items = action.payload.res.items;
                state.all_items = action.payload.res;
                // -- update price --
                state.cartUser.total_products_price = action.payload.res.total_amount;
                state.cartUser.delivery_total_price =
                    action.payload.res.total_amount_with_delivery;
                toast.info(t("removed_item"));
            })
            .addCase(RequestDeleteGuestItem.rejected, (state, action: any) => {
                state.loading = false;
            });

        // update Item
        builder
            .addCase(RequestUpdateGuestItem.pending, (state) => {
                state.loading = true;
            })
            .addCase(RequestUpdateGuestItem.fulfilled, (state, action) => {
                state.loading = false;
                // update quantity --
                state.items = action.payload.res.items
                state.all_items = action.payload?.res;
                // -- update price --
                state.cartUser.total_products_price = action.payload.res.total_amount;
                state.cartUser.delivery_total_price =
                    action.payload.res.total_amount_with_delivery;

            })
            .addCase(RequestUpdateGuestItem.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});

export const { removeCartID, clearCart } = guestCartSlice.actions;

export default guestCartSlice.reducer;
