import { Box, Typography } from "@mui/material";
import React from "react";

const HeroHeading = ({heading} : {heading?: string}) => {
  return (
    <Box mb="20px" sx={{width:"100%"}}>
      <Typography
        sx={{ fontWeight: "700", fontSize: "24px", mb: "16px",  }}
      >
        {heading}
      </Typography>
      <Box
        sx={{
          width: "20%",
          height: "1px",
          background: "rgba(223, 223, 223, 1)",
        }}
      ></Box>
    </Box>
  );
};

export default HeroHeading;
