import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import HeroHeading from "../../components/common/HeroHeading";
import PhoneField from "../../components/common/inputs/phoneField";
import { MainButton } from "../../style/style";
import ControlField from "../../components/common/inputs/controlField";
import ProfileLayout from "../../components/profile/profileLayout";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {
  RequestGetProfile,
  RequestUpdateCustomer,
} from "../../store/profile/profileSlice";
import { setUserDetails } from "../../store/user/user";
import { setOpenLogin, setSignType } from "../../store/globalSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CompareValues, PhoneUserInEgypt } from "../../utils/helper-function";

const AccountDetailsEdit = () => {
  // ---------- SELECTOR ----------
  const { userDetails } = useSelector((state: RootState) => state.user);
  const { profile } = useSelector(
    (state: RootState) => state.profile
  );

  // ---------- DISPATCH ----------
  const dispatch = useDispatch<AppDispatch>();

  // ---------- CONSTANT ----------
  const crumbs = [
    { label: `${t("Home")}`, link: "/", active: false },
    { label: `${t("profile")}`, link: "/profile", active: false },
    { label: `${t("editprofile")}`, link: "/profile/edit", active: true },
  ];

  // --------- Formik ----------
  const formik = useFormik({
    initialValues: {
      first_name: "",
      phone: "",
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required(`${t("Please first name")}`),
      phone: Yup.string()
        .required(`${t("Please enter a phone number")}`)
        .matches(
          /^[0-9]{10,11}$/,
          `${t("Phone number must be 10 or 11 numbers")}`
        ),
    }),
    onSubmit: (values) => {
      console.log("Submit", values);
      handleSubmit();
    },
  });

  // ---------- GLOBAL FUNC ----------
  const handleSubmit = () => {
    // compare values with current data
    const newValue = CompareValues(
      {
        first_name: formik.values.first_name,
        phone: PhoneUserInEgypt(formik.values.phone),
      },
      profile
    );

    // if no change return false
    if (!newValue) {
      return false;
    }

    // ------- UPDATE CUSTOMER ---------
    dispatch(RequestUpdateCustomer(newValue)).then((result) => {

      if (result.type === "RequestUpdateCustomer/fulfilled") {

        // -------- if user Edit Phone :- show verfiy Phone ---------
        if (newValue.phone) {
          dispatch(setOpenLogin(true));
          dispatch(setSignType("editProfile"));
        }

        // ADD new value TO LOCALSTOARGE
        const local: any = localStorage.getItem("userDetails");
        const localObj: any = JSON.parse(local);
        let localStr = { ...localObj, ...newValue };
        localStorage.setItem("userDetails", JSON.stringify(localStr));

        // UPDATE USER DETAILS IN REDUX
        dispatch(
          setUserDetails({
            ...userDetails,
            ...newValue,
          })
        );
      }
    });
  };

  // ----------- ONCE CHANGE -----------
  useEffect(() => {
    dispatch(RequestGetProfile()).then((result) => {
      const { first_name, phone } = result.payload;
      formik.setFieldValue("first_name", first_name);
      formik.setFieldValue("phone", phone.slice(3));
    });
  }, [dispatch]);

  return (
    <ProfileLayout crumbs={crumbs}>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          {/* ---------- HERO HEADING ---------- */}
          <HeroHeading heading={t("editprofile")} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              mt: "24px",
              width: "100%",
            }}
          >
            {/* ---------- fullname---------- */}
            <Box>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", mb: "8px" }}
              >
                {t("fullname")}
              </Typography>
              <ControlField
                type="text"
                id="first_name"
                sx={{ width: { md: "25%", sm: "50%", xs: "100%" } }}
                name="first_name"
                value={formik.values?.first_name}
                onChange={(e: { target: { value: string } }) => {
                  formik.setFieldValue("first_name", e.target.value);
                }}
              />
            </Box>

            {/* ---------- Mobile NUMBER---------- */}
            <Box>
              <PhoneField
                label={t("mobile_number")}
                id="phone"
                name="phone"
                sx={{
                  width: {
                    md: "25% !important",
                    sm: "50% !important",
                    xs: "100%",
                  },
                }}
                value={formik.values?.phone}
                onChange={(e: any) => {
                  formik.setFieldValue("phone", e);
                }}
              />
            </Box>
          </Box>
          <MainButton
            sx={{ mt: "40px", padding: { md: "12px 141px" } }}
            type="submit"
          >
            {t("editprofile")}
          </MainButton>
        </Box>
      </form>
    </ProfileLayout>
  );
};

export default AccountDetailsEdit;
