import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {BASEURL} from "../../data/apis";
import {toast} from "react-toastify";
import {t} from "i18next";

export interface ComparisonState {
    value: number;
    loading: boolean;
    compare: any;
}

const initialState: ComparisonState = {
    value: 0,
    loading: false,
    compare: [],
};

export const RequestPostCompare = createAsyncThunk(
    "RequestPostCompare",
    async (id: any, ThunkApi) => {
        const state: any = ThunkApi.getState();
        const token: any = state.user.user;
        try {
            const response = await axios.post(`${BASEURL}/products/${id}/compare/`, {}, {
                headers: {Authorization: `Token ${token}`},
            });

            return response.data;
        } catch (error: any) {

            return ThunkApi.rejectWithValue(error);
        }
    }
);

export const RequestGetCompare = createAsyncThunk(
    "RequestGetCompare",
    async (_, ThunkApi) => {
        const state: any = ThunkApi.getState();
        const token: any = state.user.user;
        try {
            const response = await axios.get(`${BASEURL}/comparison/`, {
                headers: {Authorization: `Token ${token}`},
            });

            return response.data;
        } catch (error: any) {
            return ThunkApi.rejectWithValue(error.data);
        }
    }
);


export const comparison = createSlice({
    name: "comparison",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // post To Comare
        builder
            .addCase(RequestPostCompare.pending, (state) => {
                state.loading = true;
            })
            .addCase(RequestPostCompare.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload?.message === "deleted") {
                    toast.error(t('wishlist_deleted'))
                } else {
                    toast.success(t('comparsion_done'))
                }
            })
            .addCase(RequestPostCompare.rejected, (state, action: any) => {
                state.loading = true;
                toast.error(action?.payload?.response?.data?.error)
            });

        // get To Comare
        builder
            .addCase(RequestGetCompare.pending, (state) => {
                state.loading = true;
            })
            .addCase(RequestGetCompare.fulfilled, (state, action) => {
                state.loading = false;
                state.compare = action.payload?.at(0)?.product_compare
            })
            .addCase(RequestGetCompare.rejected, (state) => {
                state.loading = true;
            });
    },
});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = comparison.actions

export default comparison.reducer;
