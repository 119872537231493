import React, { useEffect } from "react";
import ProfileLayout from "../../components/profile/profileLayout";
import HeroHeading from "../../components/common/HeroHeading";
import { Box, Typography } from "@mui/material";
import PasswordField from "../../components/common/inputs/passwordField";
import { MainButton } from "../../style/style";
import { t } from "i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RequestChangePassword } from "../../store/user/user";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {
  RequestGetProfile,
} from "../../store/profile/profileSlice";
import { editIcon } from "../../assets/svg/svg";
import { useNavigate } from "react-router-dom";

const Account = () => {
  const errorChange = useSelector((state: RootState) => state.user.errorChange);
  const { profile } = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch<AppDispatch>();

  const formik = useFormik({
    initialValues: {
      new_password: "",
      old_password: "",
    },
    validationSchema: Yup.object().shape({
      new_password: Yup.string()
        .required(`${t("pleaseenterpassword")}`)
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)/,
          `${t("Password must contain at least one letter and one number")}`
        )
        .min(8, `${t("Password is too short - should be 8 chars minimum.")}`),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const { new_password, old_password } = values;
      dispatch(RequestChangePassword({ new_password, old_password })).then((response) => {
        if (response.type === "RequestChangePassword/fulfilled") {
          setSubmitting(false);
          navigate("/")
        } else {
          setSubmitting(false);
        }
      });
    },
  });
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(RequestGetProfile());
  }, []);
  const crumbs = [
    { label: ` ${t("Home")}`, link: "/", active: false },
    { label: `${t("profile")}`, link: `/profile`, active: true },
  ];
  return (
    <ProfileLayout sx={{ height: "100%" }} crumbs={crumbs}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: { md: "97%" },
        }}
      >
        <HeroHeading heading={t("account details")} />
        <Box
          onClick={() => navigate("/profile/edit")}
          sx={{ cursor: "pointer" }}
        >
          {editIcon}
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Typography
            sx={{ flex: "0 0 170px", fontWeight: "500", fontSize: "18px", textTransform: 'capitalize' }}
          >
            {t("name")}
          </Typography>
          <Typography sx={{ color: "#505050", fontSize: "18px" }}>
            {profile?.first_name}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Typography
            sx={{ flex: "0 0 170px", fontWeight: "500", fontSize: "18px" }}
          >
            {t("Mobile numbers")}
          </Typography>
          <Typography
            sx={{ color: "#505050", fontSize: "18px", direction: "ltr" }}
          >
            {profile?.phone?.slice(2)}
          </Typography>
        </Box>
      </Box>
      <Box mt="50px">
        <HeroHeading heading={t("Change password")} />

        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <Box>
              <PasswordField
                id="old_password"
                name="old_password"
                label={t("old password")}
                sx={{ width: { md: "426px" } }}
                value={formik.values.old_password}
                onChange={formik.handleChange}
                errors={
                  formik.touched.old_password && formik.errors.old_password
                    ? formik.errors.old_password
                    : ""
                }
              />
            </Box>
            <Box>
              <PasswordField
                id="new_password"
                name="new_password"
                label={t("New Password")}
                sx={{ width: { md: "426px" } }}
                value={formik.values.new_password}
                onChange={formik.handleChange}
                errors={
                  formik.touched.new_password && formik.errors.new_password
                    ? formik.errors.new_password
                    : ""
                }
              />
              <Typography sx={{ color: "red", mt: "10px" }}>
                {errorChange && errorChange}
              </Typography>
            </Box>
          </Box>
          <button
            onSubmit={() => formik.handleSubmit()}
            style={{ display: "none" }}
          >
            test
          </button>
        </form>

        <MainButton
          sx={{ mt: "40px", mb: "30px", padding: { md: "12px 86px" } }}
          onClick={() => formik.handleSubmit()}
        >
          {formik.isSubmitting ? "Submitting..." : t("change_password")}
        </MainButton>
      </Box>
    </ProfileLayout>
  );
};

export default Account;
