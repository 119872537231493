import { Box, Container, Grid } from "@mui/material";
import LastNav from "../../components/layout/lastNav";
import FooterProfile from "./footerProfile";
import { Link, useLocation } from "react-router-dom";
import ProfileTab from "./profileTab";
import BreadCrumbs from "../common/breadCrumbs";
import { t } from "i18next";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { setUser } from "../../store/user/user";
import { clearCart } from "../../store/cart/cartSlice";
const activeAccount = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.42899 18.8578L16.2861 12.0007L9.42899 5.14355"
      stroke="url(#paint0_linear_467_16999)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_467_16999"
        x1="12.9338"
        y1="5.14355"
        x2="12.7367"
        y2="18.855"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5841F" />
        <stop offset="1" stopColor="#ED1C24" />
      </linearGradient>
    </defs>
  </svg>
);

const ProfileLayout = ({ children ,crumbs}: any) => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const pathname = location.pathname;
  const route = pathname.replace("/profile/", "");

  // const crumbs = [
  //   { label: "Home", link: "/", active: false },
  //   { label: route, link: `/profile/${route}`, active: true },
  // ];

  return (
    <>
      <Container sx={{ display: "flex", flexDirection: "column", flex: "1" }}>
        <Box mb="40px" mt="48px" sx={{px:{md:"30px"}}}>
          <BreadCrumbs crumbs={crumbs} />
        </Box>
        <Grid container spacing={4} mb="50px">
          <Grid item md={4} lg={3} xl={2} sx={{width:"100%"}}>
            <Box sx={{width:"100%"}}>
              <ProfileTab text={t("account details")} path={"/profile"} />

              <ProfileTab text={t("orders")} path={"order"} />

              <ProfileTab text={t("Privacy Policy")} path={"privacy"} />
              <ProfileTab text={t("refund_privacy")} path={"refund&returnsPolicy"} />
              <ProfileTab text={t("terms_privacy")} path={"terms&condition"} />

              <Box onClick={() => {
                Cookies.remove('token')
                localStorage.removeItem("userDetails")
                dispatch(setUser())
                dispatch(clearCart())
              }}>
                <p className="main-text list-profile" style={{cursor:"pointer",color:"#ED1C24"}}>{t("logout")}</p>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={10} md={8} lg={9} xl={10}>
            {children}
          </Grid>
        </Grid>
      </Container>
      <FooterProfile />
    </>
  );
};

export default ProfileLayout;
