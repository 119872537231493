import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, styled, Stack } from "@mui/material";

import { colors } from "../../utils/theme";
import { IconBox, MainButton } from "../../style/style";
import BasicTable from "../../components/productDetails/table";

import { BiSolidDownArrow } from "react-icons/bi";
import { BiSolidUpArrow } from "react-icons/bi";
import IosShareIcon from "@mui/icons-material/IosShare";

import { NavbarNav } from "../../pages/productDetails";

import { t } from "i18next";

import Rating from "@mui/material/Rating";
import useCustomHooks from "../../hooks/customHooks";
import { BsBookmark } from "react-icons/bs";

import useAddToCart from "../../hooks/useAddToCart";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { setOpenLogin, setSignType } from "../../store/globalSlice";
import useCompare from "../../hooks/useCompare";
import { BiTransfer } from "react-icons/bi";
import { toast } from "react-toastify";
import VideoCard from "../home/videoCard";

interface productState {
  id: 1;
  name: "mixer pink";
  description: "<h1><strong>material:</strong></h1>\r\n\r\n<ul>\r\n\t<li><strong>steel</strong></li>\r\n\t<li><strong>plastic&nbsp;</strong></li>\r\n\t<li><strong>foam</strong></li>\r\n</ul>";
  price: "2000.00";
  category: 1;
  quantity: 500;
}

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "rgba(240, 194, 77, 1)",
  },
  "& .MuiRating-iconHover": {
    color: "rgba(240, 194, 77, 1)",
  },
});
const ColorCircle = styled(Box)<{ selected: boolean }>(({ selected }) => ({
  width: "35px",
  height: "35px",
  borderRadius: "50%",
  border: selected ? "2px solid #F5841F" : "2px solid transparent",
  cursor: "pointer",
}));
const ProductsInfo = ({ product, onColorChange }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [quantity, setQuantity] = useState<any>(1);
  const token = useSelector((state: RootState) => state.user.user);
  const handleCompare = useCompare();
  const [selectedColorID, setSelectedColorID] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleAddItem = useAddToCart();
  const handleConsole = useCustomHooks();

  const handleSaveItem = () => {
    if (token) {
      handleConsole(product?.id, product?.is_wishlist);
    } else {
      dispatch(setOpenLogin(true));
      dispatch(setSignType("login"));
    }
  };

  const linkRef = useRef<any>(null);
  const [copied, setCopied] = useState(false);
  const copyToClipboard = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
    toast.success(t("copied_done"));
  };

  const [isHoveringSaved, setIsHoveringSaved] = useState(false);
  const [isHoveringTransfer, setIsHoveringTransfer] = useState(false);
  const [isHoveringCopied, setIsHoveringCopied] = useState(false);

  const handleColorSelect = (color: any) => {
    if (color.type === "main") {
      console.log(color);
      setSelectedColorID(color.id);
      setSelectedColor(null);
      setSelectedImage(color?.images?.lenght>0&& color?.images[0]?.image || color?.mainImage);
      console.log(selectedImage);
      onColorChange(color.images); 
    }else{
      setSelectedColorID(color.id);
      setSelectedColor(color.hex_value);
      setSelectedImage(color.images[0].image);
      console.log(color);
      onColorChange(color.images); 
    }
  };
  useEffect(() => {
    if (product.color && product.color.length > 0) {
      setSelectedColorID(product.id);
    }
  }, [product])
  return (
    <Stack direction="row">
      <Box sx={{ flex: 1 }}>
        <Box>
          <Typography
            component={"h1"}
            sx={{
              fontSize: "24px",
              fontWeight: "600",
              lineHeight: "29.26px",
              maxWidth: "607px",
              mb: "16px",
            }}
          >
            {product?.name}
          </Typography>
          <Box>
            <StyledRating
              name="half-rating"
              value={
                product?.avg_rating && !isNaN(product.avg_rating)
                  ? product.avg_rating
                  : 0
              }
              precision={1}
              readOnly
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            mt: "6px",
            mb: "24px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Typography
              component={"span"}
              sx={{ color: "", fontWeight: "400" }}
            >
              {product?.total_ratings_count} {t("ratings")}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "3px",
              height: "3px",
              background: "#D9D9D9",
              borderRadius: "50%",
            }}
          ></Box>
          <Typography>
            {product?.avg_rating} {t("star average")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: "24px" }}>
          <Typography
            sx={{
              color: colors.blue[100],
              fontWeight: "600",
              fontSize: "28px",
            }}
            component={"span"}
          >
            {product?.price} {t("egp")}
          </Typography>
          <Typography
            component={"span"}
            sx={{
              textDecoration: "line-through",
              fontWeight: "400",
              fontSize: "18px",
              ml: "10px",
              mr: "10px",
            }}
          >
            {/* 4,200.00 */}

            {product?.old_price > 0 && product?.old_price}
          </Typography>
        </Box>

        <Box sx={{ fontSize: "18px", mb: "32px" }}>
          <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
            {t("About This Item")}
          </Typography>
          <NavbarNav>
            {product?.about_item?.split("\r\n").map((ele: string) => {
              if (ele.length > 0) {
                return <li>{ele}</li>;
              } else {
                return <p></p>;
              }
            })}
          </NavbarNav>
        </Box>

        {
          product?.video_review && <a target="_blank" rel="noreferrer" href={product?.videos[0]?.video} style={{ fontSize: "18px", fontWeight: "600", marginBottom: "20px", display: 'inline-block', textDecoration: "none" }}>
            {t("Video review")}
          </a>

        }

        {product?.video_review && <Box mb="30px" sx={{ "& p": { whiteSpace: "wrap" } }}>
          <VideoCard video={product?.videos[0]} />
        </Box>}
        {/* Color Selector */}
        <Typography sx={{ fontSize: "18px", fontWeight: "600", marginBottom: "10px" }}>
          {t("selectColor")}
        </Typography>
        <Box sx={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
          <ColorCircle
            selected={selectedColorID === product.id}
            onClick={() => handleColorSelect({ id: product.id, images: product.additional_images,mainImage:product?.image, type: "main" })}
            sx={{
              backgroundColor: "black",
              // boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.08)",
            }}
          />
          {product?.color?.map((color: any) => (
            <ColorCircle
              key={color.id}
              selected={selectedColorID === color.id}
              onClick={() => handleColorSelect(color)}
              sx={{
                backgroundColor: color.hex_value,
              }}
            />
          ))}
        </Box>

        <Box mb="40px" sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
          <Box
            sx={{
              display: "flex",
              border: "0.5px solid #8A8A8A",
              padding: "8px 15px",
              borderRadius: "8px",
            }}
          >
            <input
              type="number"
              style={{
                border: "none",
                outline: "none",
                width: "60px",
                fontSize: "18px",
                fontWeight: "500",
                padding: "10px",
              }}
              className="input-number"
              placeholder="1"
              value={quantity}
              onChange={(e: any) => {
                if (e.target.value <= 0) return e.target.value
                setQuantity(parseInt(e.target.value));
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                "& svg:hover": {
                  color: "#F5841F",
                },
              }}
            >
              <BiSolidUpArrow
                style={{ fontSize: "10px", cursor: "pointer" }}
                onClick={() => {
                  if (quantity >= product.quantity) {
                    toast.error(t("quantity-enough"));
                    return false;
                  }
                  setQuantity(parseInt(quantity) + 1);
                }}
              />
              <BiSolidDownArrow
                style={{ fontSize: "10px", cursor: "pointer" }}
                onClick={(old) => {
                  if (quantity <= 1) return
                  setQuantity(parseInt(quantity) - 1);
                  if (quantity <= 1) {
                    setQuantity(1);
                  }
                }}
              />
            </Box>
          </Box>
          <MainButton
            sx={{ width: { sm: "340px", color: "#fff" } }}
            onClick={() => {
              if (product?.quantity === 0) {
                toast.error(t("Restocking"));
                return false;
              }
              handleAddItem(quantity, product?.id, selectedColor, selectedImage);
            }}
          >
            {product?.quantity > 0 && (
              <span style={{ display: "inline-block", margin: "0 8px" }}>
                +
              </span>
            )}
            {product?.quantity === 0 ? t("Restocking") : t("AddToCart")}
          </MainButton>
        </Box>

        <Box>
          {product.material !== null ||
            product?.package_dimensions !== null ||
            product?.kilograms_capacity !== null ||
            product?.number_of_excitations !== null ||
            product?.special_features !== "" ||
            product?.item_weight !== null ||
            product?.date_first_available !== null ? (
            <Typography
              sx={{ fontSize: "18px", fontWeight: "600", mb: "24px" }}
            >
              {t("Technical Details")}
            </Typography>
          ) : (
            ""
          )}
          <BasicTable tech={product} />
        </Box>
      </Box>
      <Box sx={{ justifySelf: "end" }}>
        <Box>
          <IconBox
            sx={{
              width: "48px !important",
              height: "48px !important",
              mb: "16px",
              ml: "16px",
              filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08))",
              background:
                isHoveringSaved || product?.is_wishlist
                  ? "linear-gradient(90deg, #F5841F 0.35%, #ED1C24 99.64%) !important"
                  : "#fff",
              color: isHoveringSaved || product?.is_wishlist ? "#fff" : "#000",
            }}
            onMouseOver={() => setIsHoveringSaved(true)}
            onMouseOut={() => setIsHoveringSaved(false)}
            onClick={() => {
              {
                if (token) {
                  handleConsole(product?.id, product?.is_wishlist);
                } else {
                  dispatch(setOpenLogin(true));
                  dispatch(setSignType("login"));
                }
              }
            }}
          >
            <BsBookmark style={{ fontSize: "18px" }} />
          </IconBox>
          <IconBox
            sx={{
              width: "48px !important",
              height: "48px !important",
              mb: "16px",
              ml: "16px",
              filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08))",
              background:
                isHoveringTransfer || product?.is_comparison
                  ? "linear-gradient(90deg, #F5841F 0.35%, #ED1C24 99.64%) !important"
                  : "#fff",
              color:
                isHoveringTransfer || product?.is_comparison ? "#fff" : "#000",
            }}
            onMouseOver={() => setIsHoveringTransfer(true)}
            onMouseOut={() => setIsHoveringTransfer(false)}
            onClick={() => {
              if (token) {
                handleCompare(product?.id, product?.is_comparison);
              } else {
                dispatch(setOpenLogin(true));
              }
            }}
          >
            <BiTransfer style={{ fontSize: "18px" }} />
          </IconBox>
          <input
            type="text"
            value="https://www.example.com" // Replace with your link
            ref={linkRef}
            readOnly
            style={{ display: "none" }}
          />
          <IconBox
            sx={{
              width: "48px !important",
              height: "48px !important",
              ml: "16px",
              filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08))",
              background: isHoveringCopied
                ? "linear-gradient(90deg, #F5841F 0.35%, #ED1C24 99.64%) !important"
                : "#fff",
              color: isHoveringCopied ? "#fff" : "#000",
            }}
            onMouseOver={() => setIsHoveringCopied(true)}
            onMouseOut={() => setIsHoveringCopied(false)}
            onClick={copyToClipboard}
          >
            <IosShareIcon />
            {/* {uploadIcon} */}
          </IconBox>
        </Box>
      </Box>
    </Stack>
  );
};

export default ProductsInfo;
