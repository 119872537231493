import * as React from "react";
import { useRef, useState } from "react";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import phoneImage from "../../../assets/svg/egypt.png";
import { Box, Typography } from "@mui/material";

interface PhoneFieldProps {
  sx?: {};
  label?: string;
  id?: string;
  name?: string;
  onChange?: any;
  value?: string;
  errors?: any;
  type?: any;
  onChangeValue?: any;
}
export default function PhoneField({
  sx,
  label,
  id,
  name,
  onChange,
  value,
  errors,
  onChangeValue,
  type = "number",
}: PhoneFieldProps) {
  const inputRef = useRef<any>();
  const [phoneState, setPhoneState] = useState();
  const [isFocused, setIsFocused] = useState(false);


  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const isNumberType = type === "number";

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (isNumberType) {
      // Allow only numbers (0-9)
      // const sanitizedValue = inputValue.replace(/[^0-9]|^0+(?!$)/g, "");
      const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
      event.target.value = sanitizedValue;
    }

    // if (inputValue[0] === '0') {
    //   onChange(inputValue.slice(1));
    //   onChangeValue(event.target.value)

    // } else {

    // }
    onChange(event.target.value);
  };
  return (
    <>
      {label && (
        <Typography sx={{ fontSize: "16px", fontWeight: "500", mb: "8px", textTransform:"capitalize" }}>
          {label}
        </Typography>
      )}
      <Box
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          padding: "1.8px 20px",
          fontSize: "16px",
          borderRadius: "12px",
          background: isFocused ? "#fff" : "#F2F2F2",
          borderColor: "#F2F2F2",
          boxShadow: isFocused
            ? "2px 2px 10px 0px rgba(237, 28, 36, 0.16), -2px -2px 10px 0px rgba(237, 28, 36, 0.16)"
            : 0,
          direction: "ltr",
          ...sx,
        }}
        onClick={() => inputRef.current.focus()}
      >
        <span style={{ color: "#7F7F7F", fontWeight: "500" }}>+20</span>
        <Box sx={{ p: "10px", mt: "5px" }}>
          <img src={phoneImage} alt="egypt" />
        </Box>
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <InputBase
          sx={{ ml: 1, flex: 1, ...sx }}
          placeholder=""
          inputProps={{ "aria-label": "phone number" }}
          type="text"
          ref={inputRef}
          fullWidth={false}
          name={name}
          id={id}
          value={value}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={(e: any) => {
            setPhoneState(e.target.value);
            localStorage.setItem("phone", e.target.value);
            handleInputChange(e);
          }}
        />
      </Box>

      <Typography component="span" sx={{ color: "red", mt: "5px" }}>
        {errors}
      </Typography>
    </>
  );
}
