import React from "react";
import TextField from "@mui/material/TextField";
import { colors } from "../../../utils/theme";
import { Typography } from "@mui/material";

interface InputFieldProps {
  placeholder?: string;
  id?: string;
  name?: string;
  value?: string;
  onChange?: any;
  errors?: string;
  type?: string;
}

const InputField = ({
  placeholder,
  id,
  name,
  value,
  onChange,
  errors,
  type = "text",
}: InputFieldProps) => {

  const isTextType = type === "text";
  const isNumberType = type === "number";

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (isTextType) {
      // Allow only characters (letters and spaces)
      const sanitizedValue =  inputValue.replace(/[^A-Za-z\s\u0600-\u06FF]/g, "");
      event.target.value = sanitizedValue;
    }

    if (isNumberType) {
      // Allow only numbers (0-9)
      // const sanitizedValue = inputValue.replace(/[^0-9]|^0+(?!$)/g, "");
      const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
      event.target.value = sanitizedValue;
    }

    onChange(event);
  };
  return (
    <>
      <TextField
        id={id}
        name={name}
        label=""
        variant="outlined"
        placeholder={placeholder}
        fullWidth
        value={value}
        onChange={handleInputChange}
        type={type === "address" ? "text" :"text"}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#D9D9D9",
            },
            "&:hover fieldset": {
              borderColor: colors.blue[400],
            },
            "&.Mui-focused fieldset": {
              borderColor: colors.blue[400],
            },
          },
        }}
      />
      <Typography component="span" sx={{color:"red", mt:"5px"}}>{errors}</Typography>
    </>
  );
};

export default InputField;
