import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProductCart from "../cart/productCart";
import qrCode from "../../assets/qrcode.png";

import { Box } from "@mui/material";
import styled from "@emotion/styled";
import QRCode from "react-qr-code";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export const Lead = styled(Box)({
  fontSize: "15px",
  fontWeight: "400",
});
export const LeadBold = styled(Box)({
  fontSize: "15px",
  fontWeight: "500",
});

export const Flex = styled(Box)({
  display: "flex",
  gap: "15px",
  marginBottom: "16px",
});

export default function CustomAccordion({ order , order_id }: any) {
  const lang = useSelector((state: RootState) => state.global.mainLang)

  return (
    <Box
      sx={{
        "& .MuiAccordion-root:before": {
          background: "transparent",
        },
      }}
    >
      <Box sx={{boxShadow: "0px 2px 4px -2px rgba(231, 231, 231, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px -2px 4px -2px rgba(16, 24, 40, 0.06), 0px -4px 8px -2px rgba(231, 231, 231, 0.10)", padding:"25px", mb:"20px", background:"#FAFAFA", borderRadius:"8px"}}>
        <Box sx={{}}>
          <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#1B1B1B"}}>
            {order?.user_firstname}
          </Typography>
          <Typography sx={{color:"#9D9D9D", direction:"ltr", textAlign: lang === "ar" ? "right" : "left"}}>
            {order?.user_phone}
          </Typography>
        </Box>
      </Box>
      <Accordion sx={{ mb: "24px", borderRadius: "8px", boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ background: "#E7E7E7", borderRadius: "8px" }}
        >
          <Typography
            sx={{ padding: "9px", fontSize: "15px", fontWeight: "500" }}
          >
            {t('ordersummary')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ borderRadius: "8px", background: "#FAFAFA !important" }}
        >
          {/* <ProductCart /> */}
          {order?.items?.map((item: any) => {
            return <ProductCart item={item} isDelete={false} isOrder={true} order_id={order_id}  />;
          })}
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: "24px", borderRadius: "8px", boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ background: "#E7E7E7", borderRadius: "8px" }}
        >
          <Typography
            sx={{ padding: "9px", fontSize: "15px", fontWeight: "500" }}
          >
            {t('shippingdetails')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ borderRadius: "8px", background: "#fafafa !important" }}
        >
          <Flex>
            <Lead sx={{ flex: "0 0 150px" }}>{t('paymentmethod')}</Lead>
            <LeadBold>{order?.payment_method}</LeadBold>
          </Flex>
          {/* {order?.payment_method ==="credit card"&&
          <Flex>
            <Lead sx={{ flex: "0 0 150px" }}>Credit card number</Lead>
            <LeadBold>**** **** **** 7958</LeadBold>
          
          </Flex>
        } */}
          <Flex>
            <Lead sx={{ flex: "0 0 150px" }}>{t('shippingaddress')}</Lead>
            <LeadBold>{order?.address}</LeadBold>
          </Flex>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: "24px", borderRadius: "8px", boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ background: "#E7E7E7", borderRadius: "8px" }}
        >
          <Typography
            sx={{
              padding: "9px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#1B1B1B",
            }}
          >
            {t('scanqr')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ borderRadius: "8px", background: "#FAFAFA !important" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: "20px",
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: "400" }}>
              {t('scanhere')}
            </Typography>
            <Box>
              <QRCode
                size={75}
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  width: "100%",
                }}
                value={
                  order?.qr_code || "894117e2-1326-44ba-b87e-8fe111a05df1"
                }
                viewBox={`0 0 256 256`}
              />
              {/* <img src={qrCode} alt="qrcode" /> */}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
