import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { RequestPostToCart } from "../store/cart/cartSlice";
import {
  RequestPostToGuestCart,
} from "../store/guestCart/guestCart";

function useAddToCart() {
  const dispatch = useDispatch<AppDispatch>();

  // token
  const user = useSelector((state: RootState) => state.user.user);
  const guestUser = useSelector((state: RootState) => state.guestCart.cartID);


  
//   quantity: any, product_id: any
  const handleAddItem = (quantity: any, product_id: any,color:any,image:any) => {
    // setOpenDrawer(true);
    // dispatch(setOpenDrawerCart(true))
    if (user) {
      dispatch(RequestPostToCart({ quantity: quantity, product_id: product_id ,color_hex:color,image:image}));
    } 
    else {
      dispatch(
        RequestPostToGuestCart({ quantity: quantity, product_id: product_id ,color_hex:color,image:image})
      );
    } 
    // if (guestUser !== null)
    // else {
    //   dispatch(()).then((result) => { 
    //     dispatch(
    //       RequestPostToGuestCart({ quantity: quantity, product_id: product_id })
    //     );
    //   });
    // }
  };

  return handleAddItem;
}

export default useAddToCart;
