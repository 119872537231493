import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { useCallback } from "react";
import {
  RequestWishList
} from "../store/wishlist/wishlistSlice";
import { RequestDeleteItem, RequestPostToCart } from "../store/cart/cartSlice";
import {
  RequestDeleteGuestItem,
  RequestPostToGuestCart,
} from "../store/guestCart/guestCart";

function useRemoveItem() {
  const dispatch = useDispatch<AppDispatch>();

  // token
  const user = useSelector((state: RootState) => state.user.user);
  const guestUser = useSelector((state: RootState) => state.guestCart.cartID);

  //   quantity: any, product_id: any

  const handleRemoveItem = (id: number) => {
    if (user !== null) {
      dispatch(RequestDeleteItem(id));
    } else {
      // console.log(id)
      dispatch(RequestDeleteGuestItem(id));
    }
  };

  return handleRemoveItem;
}

export default useRemoveItem;
