import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { MainButton } from "../../style/style";
import PasswordField from "../common/inputs/passwordField";
import PhoneField from "../common/inputs/phoneField";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { RequestForgotPassword } from "../../store/user/user";
import { setSignType } from "../../store/globalSlice";
import { t } from "i18next";

const ForgotPassword = () => {
  const [phone, setPhone] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const {loading, errorMsg, errorForgot} = useSelector((state: RootState) => state.user)
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%", 
    justifyContent: "space-between"
    }}>
      <Box>
        <Typography sx={{ fontSize: "36px", fontWeight: "600" }}>
          {t('forgot_passowrd!')}
        </Typography>
        <Typography sx={{ color: "#505050", mt: "8px", fontWeight: "400" }}>
          {t('please_enter_mobile')}
        </Typography>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(RequestForgotPassword({ phone })).then((result) => {
              if(result.type === "RequestForgotPassword/fulfilled") {
                dispatch(setSignType("reset"))
              }
            });
          }}
        >
          <Box mt="32px">
            <PhoneField
              label={t("mobile_number")}
              onChange={(e: any) => setPhone(e)}
            />

            <Typography sx={{color:"red", mt:"10px"}}>{errorForgot && errorForgot}</Typography>
          </Box>
        </form>
      </Box>
      <Box sx={{ mt: "40px" }}>
        <MainButton
          sx={{ width: "100%" }}
          onClick={() => {
            dispatch(RequestForgotPassword({ phone })).then((result) => {
              if(result.type === "RequestForgotPassword/fulfilled") {
                dispatch(setSignType("verfiyForget"))
              }
            });
          }}
        >
          {t('next')}
        </MainButton>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
