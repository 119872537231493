export const SVGComplain = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
  >
    <g filter="url(#filter0_d_151_2037)">
      <circle cx="40" cy="36" r="30" fill="url(#paint0_linear_151_2037)" />
    </g>
    <defs>
      <filter
        id="filter0_d_151_2037"
        x="0"
        y="0"
        width="80"
        height="80"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_151_2037"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_151_2037"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_151_2037"
        x1="10"
        y1="35.3333"
        x2="69.9969"
        y2="35.7645"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5841F" />
        <stop offset="1" stopColor="#ED1C24" />
      </linearGradient>
    </defs>
  </svg>
);

export const SVGMessage = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <path
      opacity="0.991"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.76172 2.21484C13.2305 2.20312 22.6993 2.21484 32.168 2.25C33.5625 2.61328 34.4414 3.49219 34.8047 4.88672C34.8516 11.3555 34.8516 17.8242 34.8047 24.293C34.4414 25.6875 33.5625 26.5664 32.168 26.9297C27.3867 26.9531 22.6055 26.9766 17.8242 27C15.032 29.2533 12.2195 31.4799 9.38672 33.6797C8.69344 33.8659 8.18951 33.6433 7.875 33.0117C7.83985 30.9962 7.8281 28.9806 7.83985 26.9648C6.48027 26.9766 5.1209 26.9648 3.76172 26.9297C2.36719 26.5664 1.48829 25.6875 1.125 24.293C1.07812 17.8242 1.07812 11.3555 1.125 4.88672C1.50109 3.49114 2.38 2.60052 3.76172 2.21484ZM4.46485 4.46484C13.4648 4.45312 22.4648 4.46484 31.4648 4.5C32.1094 4.58203 32.4726 4.94531 32.5547 5.58984C32.6016 11.5898 32.6016 17.5899 32.5547 23.5898C32.4726 24.2344 32.1094 24.5976 31.4648 24.6797C26.6367 24.7031 21.8086 24.7266 16.9805 24.75C14.6768 26.5028 12.4033 28.2957 10.1602 30.1289C10.1016 28.5591 10.0664 26.9888 10.0547 25.418C9.92581 25.1016 9.70313 24.8789 9.38672 24.75C7.74612 24.7266 6.10547 24.7031 4.46485 24.6797C3.96803 24.6483 3.62818 24.4022 3.44531 23.9414C3.32905 17.8267 3.30562 11.7096 3.375 5.58984C3.46964 4.94454 3.83291 4.56955 4.46485 4.46484Z"
      fill="white"
    />
    <path
      opacity="0.994"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.54298 11.2148C14.8243 11.2031 21.1055 11.2148 27.3867 11.25C27.878 11.4474 28.1124 11.8106 28.0899 12.3398C28.1124 12.8691 27.878 13.2323 27.3867 13.4297C21.1055 13.4766 14.8242 13.4766 8.54298 13.4297C8.05171 13.2323 7.81736 12.8691 7.83986 12.3398C7.82594 11.8044 8.06029 11.4294 8.54298 11.2148Z"
      fill="white"
    />
    <path
      opacity="0.989"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.54298 15.7148C11.8243 15.7031 15.1056 15.7148 18.3867 15.75C19.0183 16.0645 19.241 16.5684 19.0547 17.2617C18.9258 17.5781 18.7031 17.8008 18.3867 17.9297C15.1055 17.9766 11.8243 17.9766 8.54298 17.9297C8.05171 17.7323 7.81736 17.3691 7.83986 16.8398C7.82594 16.3044 8.06029 15.9294 8.54298 15.7148Z"
      fill="white"
    />
  </svg>
);
