import React from 'react'
import ProfileLayout from "../../components/profile/profileLayout";
import HeroHeading from "../../components/common/HeroHeading";
import { Box, Typography } from "@mui/material";
import { t } from "i18next";

export default function RefundAndReturnsPolicy() {
    const crumbs = [
        { label:` ${t("Home")}`, link: "/", active: false },
        { label:`${t('refund_privacy')}`, link: `/profile/refund&returnsPolicy`, active: true },
      ];
      return (
        <ProfileLayout crumbs={crumbs}>
          <HeroHeading heading={t('refund_privacy')} />
    
          <Box sx={{ fontSize: "18px", fontWeight: "600 !important",p:2,wordSpacing:"2px",whiteSpace:"break-spaces" ,wordBreak:"break-all"}}>
            <Typography mb="16px" fontSize="18px" fontWeight={600}>
             {t('textRefund1')}
            </Typography>
            <Typography mb="16px" fontSize="18px" fontWeight={500}>
              {t('textRefund2')}
            </Typography>
            <Typography mb="16px" fontSize="18px" fontWeight={500}>
              {t('textRefund3')}
            </Typography>
            <Typography mb="16px" fontSize="18px" fontWeight={500}>
              {t('textRefund4')}
            </Typography>
            <Typography mb="16px" fontSize="18px" fontWeight={500}>
             {t('textRefund5')}
            </Typography>
            <Typography mb="16px" fontSize="18px" fontWeight={500}>
              {t('textRefund6')}
            </Typography>
            <Typography mb="16px" fontSize="18px" fontWeight={500}>
              {t('textRefund7')}
            </Typography>
            <Typography mb="16px" fontSize="18px" fontWeight={600}>
              {t('textRefund8')}
            </Typography>
            <Typography mb="16px" fontSize="18px" fontWeight={500}>
            {t('textRefund9')}
            </Typography>
    
            <Typography mb="16px" fontSize="18px" fontWeight={600}>
            {t('textRefund10')}
            </Typography>
    
            <Typography mb="16px" fontSize="18px" fontWeight={500}>
             1-{t('textRefund11')}
            </Typography>
    
            <Typography mb="16px" fontSize="18px" fontWeight={500}>
               2-{t('textRefund12')}
            </Typography>
    
            <Typography mb="16px" fontSize="18px" fontWeight={600}>
            {t('textRefund13')}
            </Typography>
    
            <Typography mb="16px" fontSize="18px" fontWeight={500}>
            {t('textRefund14')}
            </Typography>
    
            <Typography mb="16px" fontSize="18px" fontWeight={600}>
              {t('textRefund15')}
            </Typography>
    
            <Typography mb="16px" fontSize="18px" fontWeight={500}>
            1-{t('textRefund16')}
            </Typography>
    
            <Typography mb="16px" fontSize="18px" fontWeight={500}>
            2-{t('textRefund17')}
            </Typography>
    
            <Typography mb="16px" fontSize="18px" fontWeight={500}>
            3-{t('textRefund18')}
            </Typography>
          </Box>
        </ProfileLayout>
      );
}
