import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface GlobalState {
  value: number;
  signType: string;
  mainLang: any;
  openDrawerLogin: boolean;
  openDrawerCart: boolean;
  openDrawerSearch: boolean;
  openLink: boolean;
  paymentUrl: string;
  paymentLoading: boolean,
}

const initialState: GlobalState = {
  value: 0,
  signType: "login",
  openDrawerLogin: false,
  openDrawerCart: false,
  openDrawerSearch: false,
  openLink: false,
  mainLang: localStorage.getItem("lang") ? localStorage.getItem("lang") : "ar",
  paymentUrl: "",
  paymentLoading: false,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setSignType: (state, action: PayloadAction<string>) => {
      state.signType = action.payload;
    },
    setMainLang: (state, action) => {
      state.mainLang = action.payload;
    },
    setOpenLogin: (state, action: PayloadAction<boolean>) => {
      state.openDrawerLogin = action.payload;
    },
    setOpenDrawerCart: (state, action: PayloadAction<boolean>) => {
      state.openDrawerCart = action.payload;
    },
    setOpenDrawerSearch: (state, action: PayloadAction<boolean>) => {
      state.openDrawerSearch = action.payload;
    },
    setOpenLink: (state, action) => {
      state.openLink = action.payload;
    },
    setPaymentUrl: (state, action) => {
      state.paymentUrl = action.payload
    },
    setPaymentLoading: (state, action) => {
      state.paymentLoading = action.payload
    }
  },
});


export const selectLanguage = (state:any) => state.global.mainLang;


// Action creators are generated for each case reducer function
export const {
  setSignType,
  setMainLang,
  setOpenLogin,
  setOpenDrawerCart,
  setOpenDrawerSearch,
  setOpenLink,
  setPaymentLoading,
  setPaymentUrl
} = globalSlice.actions;

export default globalSlice.reducer;
