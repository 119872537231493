import React from "react";
import { Box, Typography } from "@mui/material";
import { MainButton } from "../../style/style";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RequestVerifyPassword, setErrorMsg } from "../../store/user/user";
import Cookies from "js-cookie";
import { setOpenLogin } from "../../store/globalSlice";
import OTPInput from "react-otp-input";
import useMediaQuery from "@mui/material/useMediaQuery";

const VerfiyMobile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { error, errorMsg, errorVerfiy } = useSelector(
    (state: RootState) => state.user
  );
  const matches = useMediaQuery("(max-width:490px)");

  const [otpTest, setOtpTest] = React.useState("");
  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: (values) => {

      const { otp } = values;

      dispatch(
        RequestVerifyPassword({
          otp: otp + "",
          phone: localStorage.getItem("phone")
            ? localStorage.getItem("phone")
            : "",
          action: "register",
        })
      ).then((req) => {
        {
          if (req.type == "RequestVerifyPassword/fulfilled") {
            Cookies.set("token", req.payload.token);
            localStorage.setItem("userDetails", JSON.stringify(req.payload));
            dispatch(setErrorMsg(""));
            dispatch(setOpenLogin(false));
          }
        }
      });
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography sx={{ fontSize: "36px", fontWeight: "600" }}>
          {t("verify_mobile_number")}
        </Typography>
        <Typography sx={{ color: "#505050", mt: "8px", fontWeight: "400" }}>
          {t("verify_text")}
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Box
            mt="32px"
            sx={{
              "& input": {
                background: "#F2F2F2",
                borderRadius: "12px",
                border: "none",
                // padding: "12px 24px",
                marginRight: "4px",
                width: matches ?"55px !important" :"69px !important",
                height: "50px",
              },
            }}
          >
            <OTPInput
              onChange={(otp) => formik.setFieldValue("otp", otp)}
              value={formik.values.otp}
              inputStyle="inputStyle"
              numInputs={6}
              renderSeparator={<span></span>}
              renderInput={(props: any) => <input {...props} />}
            />
          </Box>

          <button type="submit" style={{ display: "none" }}>
            test
          </button>
        </form>
      </Box>

      <Box sx={{ mt: "40px" }}>
        <MainButton
          sx={{ width: "100%" }}
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          {t("submit")}
        </MainButton>
      </Box>
    </Box>
  );
};

export default VerfiyMobile;
