import React, { useEffect } from "react";
import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import ProfileLayout from "../../components/profile/profileLayout";
import { colors } from "../../utils/theme";
import orderImage from "../../assets/order-1.png";
import CustomAccordion from "../../components/common/customAccordion";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { RequestCanselOrder, RequestGetGuestOrder, RequestGetOrder, RequestGuestCancelOrder } from "../../store/order/orderSlice";
import { t } from "i18next";

import moment from "moment";
import { RequestPostGuestPayment, RequestPostPayment } from "../../store/payment/paymentSlice";
import { setOpenLink, setPaymentUrl } from "../../store/globalSlice";
import BasicModal from "../../components/common/basicModel";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "transparent",
    border: "none",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
};

const OrderDetails = () => {
    const order = useSelector((state: RootState) => state.orders.order);
    const Loading = useSelector((state: RootState) => state.orders.loading);

    const openLink = useSelector((state: RootState) => state.global.openLink);

    const paymentLoading = useSelector((state: RootState) => state.payment.loading);

    const token = useSelector((state: RootState) => state.user.user);


    const navigate = useNavigate();

    const param = useParams();
    const dispatch = useDispatch<AppDispatch>();

    // const handleClose = () => setOpen(false);

    const crumbs = [
        { label: `${t("Home")}`, link: "/", active: false },
        { label: `${t("order")}`, link: "/profile/order", active: false },
        { label: `order #${param?.id}`, link: `/profile/order/${param?.id}`, active: true },
    ];

    useEffect(() => {
        if (token) {
            dispatch(RequestGetOrder(param?.id));
        } else {
            dispatch(RequestGetGuestOrder(param?.id));
        }
    }, []);
    return (
        <ProfileLayout crumbs={crumbs}>
            <Box sx={{ background: colors.card[100], padding: "40px 24px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <img src={orderImage} alt="test" />
                    {/* payment_status: "PENDING" */}
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "20px",
                    }}>
                        {order?.order_status === "Pending" && order?.payment_method === "Card" && !Loading && (
                            <button
                                className="main-outline-btn main-text review-button"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    if (token) {
                                        dispatch(RequestPostPayment(order.id)).then((result) => {
                                            dispatch(setOpenLink(true));
                                            dispatch(setPaymentUrl(result?.payload?.redirect_url));
                                        });
                                    }else{
                                        dispatch(RequestPostGuestPayment(order.id)).then((result) => {
                                            dispatch(setOpenLink(true));
                                            dispatch(setPaymentUrl(result?.payload?.redirect_url));
                                        });
                                    }
                                }}
                            >
                                {t("continue-payment")}
                            </button>
                        )}
                        {
                            order?.order_status === "Pending" && (
                                <button
                                    className="main-outline-btn main-text review-button"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        if (token) {
                                            dispatch(RequestCanselOrder(order.id)).then((result) => {
                                                navigate("/profile/order")
                                            });
                                        } else {
                                            dispatch(RequestGuestCancelOrder(order.id)).then((result) => {
                                                navigate("/profile/order")
                                            });
                                        }
                                    }}
                                >
                                    {Loading ? <CircularProgress size={20} /> : t("cansel_order")}
                                </button>
                            )
                        }
                    </div>

                </Box>
                <Typography
                    sx={{
                        fontSize: "24px",
                        color: "#1B1B1B",
                        fontWeight: "500",
                        mb: "25px",
                    }}
                >
                    {t("order")} #{order?.id}
                </Typography>

                <Box
                    sx={{
                        display: "flex",
                        gap: "40px",
                        mb: "33px",
                        flexDirection: { md: "row", xs: "column" },
                    }}
                >
                    <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography sx={{ color: "#7F7F7F", fontWeight: "600" }}>
                            {t("status")} :
                        </Typography>
                        <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
                            {order.order_status}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography sx={{ color: "#7F7F7F", fontWeight: "600" }}>
                            {t("date")} :
                        </Typography>
                        <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
                            {moment(order?.created_at).format("MMM/DD/YYYY")}
                        </Typography>
                    </Box>
                </Box>

                <CustomAccordion order={order} order_id={order?.id} />

                {paymentLoading ? (
                    <div>
                        <Modal
                            open={paymentLoading}
                            // onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                "& .MuiBox-root": {
                                    boxShadow: "0",
                                    outline: "none",
                                },
                            }}
                        >
                            <Box sx={style}>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <CircularProgress />
                                </Box>
                            </Box>
                        </Modal>
                    </div>
                ) : (
                    <BasicModal
                        open={openLink}
                        onClose={() => dispatch(setOpenLink(false))}
                    />
                )}
            </Box>
        </ProfileLayout>
    );
};

export default OrderDetails;
