import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../data/apis";
import { toast } from "react-toastify";
import { t } from "i18next";
import { setOpenDrawerCart } from "../globalSlice";
import Cookies from "js-cookie";

interface TotalState {
  shipping_price: number | null;
  total_products_price: number | null;
  delivery_total_price: number | null;
}

export interface CartState {
  loading: boolean;
  items: any;
  cartID: any | null;
  cartUser: TotalState;
  mainCartId: string | null;
  all_items: any;
}

const initialState: CartState = {
  loading: false,
  items: [],
  all_items: [],
  cartID: null,
  mainCartId: Cookies.get("mainCartId") || null,
  cartUser: {
    shipping_price: 0,
    total_products_price: 0,
    delivery_total_price: 0,
  },
};
async function fromURLToImage(url: string): Promise<File> {
  const fileName = "image.jpg";
  const response = await fetch(url);
  const type = response.headers.get("content-type") || "image/jpeg";
  const blob = await response.blob();
  const file = new File([blob], fileName, { type });
  return await file;
}
export const RequestGetCart = createAsyncThunk(
  "RequestGetCart",
  async (data, { getState, rejectWithValue }) => {
    const state: any = getState();
    const token: string = state.user.user;
    const response = await axios.get(`${BASEURL}/cart/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  }
);

export const RequestPostToCart = createAsyncThunk(
  "RequestPostToCart",
  async (data: any, { getState, rejectWithValue, dispatch }) => {
    const state: any = getState();
    const token: string = state.user.user;
    const formData = new FormData();
    formData.append("product_id", data.product_id);
    formData.append("quantity", data.quantity);
    formData.append("color_hex", data.color_hex);
    formData.append("image",  await fromURLToImage(data.image));

    try {
      const response = await axios.post(
        `${BASEURL}/cart/add-to-cart/${data.product_id}/`,
        { ...formData },
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      dispatch(setOpenDrawerCart(true));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const RequestDeleteItem = createAsyncThunk(
  "RequestDeleteItem",
  async (id: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const token: string = state.user.user;
    const mainCardID: any = state.cart.mainCartId;
    const response = await axios.delete(
      `${BASEURL}/cart/${Cookies.get("mainCartId")}/`,
      {
        data: {
          cartitem_id: id,
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      }
    );
    return { res: response.data, id: id };
  }
);

export const RequestUpdateItem = createAsyncThunk(
  "RequestUpdateItem",
  async ({ quantity, id }: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const token: string = state.user.user;
    const maincardId: number = state.cart.mainCartId;

    try {
      const response = await axios.patch(
        `${BASEURL}/cart/${Cookies.get("mainCartId")}/`,
        {
          product_id: id,
          quantity: quantity,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return { res: response.data, quantity, id };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    clearCart: (state) => {
      state.items = [];
      state.cartUser = {
        shipping_price: 0,
        total_products_price: 0,
        delivery_total_price: 0,
      };
    },
  },
  extraReducers: (builder) => {
    // Request GET CART
    builder
      .addCase(RequestGetCart.pending, (state) => {
        state.loading = true;
        state.items = [];
        state.all_items = [];
      })
      .addCase(RequestGetCart.fulfilled, (state, action) => {
        Cookies.set("mainCartId", action?.payload?.at(0)?.id, { expires: 7 });
        state.loading = false;
        state.items = action.payload?.at(0)?.items || [];
        state.all_items = action.payload?.at(0) || [];
        state.cartID = action?.payload?.at(0)?.id;

        let sumTotal = state?.items
          ?.map((item: any) => item?.quantity * item?.product?.price)
          .reduce((total: any, current: any) => total + current, 0);

        state.cartUser.total_products_price = sumTotal;
        state.cartUser.delivery_total_price =
          sumTotal + state.cartUser.shipping_price;
          
      })
      .addCase(RequestGetCart.rejected, (state, action: any) => {
        state.loading = false;
      });

    // ADD Items To Cart
    builder
      .addCase(RequestPostToCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(RequestPostToCart.fulfilled, (state, action) => {
        const { res } = action.payload;
        state.loading = false;

        // ------------- Add Item to Cookies -------------

        Cookies.set("mainCartId", action?.payload?.id, { expires: 7 });
        state.items = action?.payload?.items;
        state.all_items = action.payload;

        state.cartUser.total_products_price = res?.total_amount;
        state.cartUser.delivery_total_price = res?.total_amount_with_delivery;

        toast.success(t("added_item"));
      })
      .addCase(RequestPostToCart.rejected, (state, action: any) => {
        state.loading = false;
        toast.error(action?.payload[0]);
      });

    // Delete Item
    builder
      .addCase(RequestDeleteItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(RequestDeleteItem.fulfilled, (state, action) => {
        const { res } = action.payload;
        state.loading = false;

        // delete item from cart --
        state.items = action.payload.res.items;
        state.all_items = action.payload.res;

        // -- update price --
        state.cartUser.total_products_price = res.total_amount;
        state.cartUser.delivery_total_price = res.total_amount_with_delivery;

        // -- TOAST SUCCESS --
        toast.info(t("removed_item"));
      })
      .addCase(RequestDeleteItem.rejected, (state, action: any) => {
        state.loading = false;
      });

    // update Item
    builder
      .addCase(RequestUpdateItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(RequestUpdateItem.fulfilled, (state, action) => {
        const { res } = action.payload;
        state.loading = false;
        // update quantity
        state.items = res.items;
        state.all_items = action.payload?.res;
        // -- update price --
        state.cartUser.total_products_price = res.total_amount;
        state.cartUser.delivery_total_price = res.total_amount_with_delivery;
      })
      .addCase(RequestUpdateItem.rejected, (state, action: any) => {
        state.loading = false;
        toast.error(action?.payload?.detail);
      });
  },
});

// Action creators are generated for each case reducer function
export const { clearCart } = cartSlice.actions;

export default cartSlice.reducer;
