import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../data/apis";



export interface VideoState {
  loading: boolean;
  error: boolean;
  errorMsg: String;
  video: [];
}

const initialState: VideoState = {
  loading: false,
  error: false,
  errorMsg: "",
  video: [],
};


export const RequestVideoReviews = createAsyncThunk(
    "RequestVideoReviews",
    async (_, ThunkApi) => {
      try {
        const response = await axios.get(
          `${BASEURL}/products_with_video_reviews/`
        );
        return response.data;
      } catch (error: any) {
        return ThunkApi.rejectWithValue(error.data);
      }
    }
  );

export const videoSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET VedioReviews
    builder
      .addCase(RequestVideoReviews.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.video = [];
      })
      .addCase(RequestVideoReviews.fulfilled, (state, action) => {
        state.loading = false;
        state.video = action.payload;
      })
      .addCase(RequestVideoReviews.rejected, (state, action: any) => {
        state.loading = false;
        state.error = false;
      });
  },
});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = videoSlice.actions

export default videoSlice.reducer;
