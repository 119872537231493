import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import i18next from 'i18next';
import { Container } from '@mui/material';
import ShopFilterParams from '../shop/shopFilterParams';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function ShopFilterDrewer({ open, onClose }: any) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 350, }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Container>
        <Box
          sx={{
            height: "100%",
            borderRadius: "16px",
            padding: "0px 0",
            mt: "52px",
            direction: i18next.language === "en" ? "ltr" : "rtl",

          }}
        >
          <ShopFilterParams />
        </Box>
      </Container>
    </Box>
  );

  return (
    <div>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => onClose && onClose()}
      >
        {list('right')}
      </Drawer>
    </div>
  );
}