import { useSelector } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { RootState } from "../../store/store";

export const colors = {
  dark: {
    100: "#1B1B1B",
  },
  red: {
    100: "#ED1C24",
    200: "#EE2E36",
    300: "#F04047",
    400: "#F15359",
    500: "#F3656A",
    600: "#F4777C",
    700: "#F6898D",
  },
  orange: {
    100: "#F5841F",
    200: "#F68E32",
    300: "#F79945",
    400: "#F8A357",
    500: "#F8AD6A",
    600: "#F9B87D",
    700: "#FAC290",
    800: "#FEF2E8",
  },
  blue: {
    100: "#1F90F5",
    200: "#359BF6",
    300: "#4BA6F7",
    400: "#62B1F8",
    500: "#78BCF9",
    600: "#8FC7FA",
    700: "#A5D2FB",
  },
  card: {
    100: "rgba(251, 251, 251, 1)",
  },
};

const CustomThemeProvider = ({ children }: any) => {
  //   const selectedLanguage = useSelector(selectLanguage);
  const lang = useSelector((state: RootState) => state.global.mainLang);

  const theme = createTheme({
    // direction: lang === "en" ? "ltr" : "rtl",
    palette: {
      primary: {
        main: colors.red[700],
        light: "white",
      },
    },
    typography: {
      fontFamily: lang === "en" ?"'montserrat', sans-serif !important" : "'gesslight', sans-serif !important",
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
