import React from "react";
import { Box } from "@mui/material";
// import "../../style/checkbox.css";

import styled from "@emotion/styled";

import { useSearchParams } from "react-router-dom";
import { extractExistingParams } from "../../utils/helper-function";

export const CheckBoxCheck = styled(Box)({
  width: "20px !important",
  height: "20px !important",
  background: "#fff",
  boxShadow: "2px 2px 10px 0px rgba(0, 0, 0, 0.08)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 !important",
  "&::before": {
    borderRadius: "0px",
    padding: "2px",
  },
  flex: "0 0 22px",
  maxWidth: "22px",
});


const CustomCheck = ({ id, name, type }: any) => {
  //---------- state ----------
  const [searchParams, setSearchParams] = useSearchParams();

  //----------- Is Checked Comes from searchParams
  const isCheckedParams = searchParams.get(type)?.split(",").includes(id.toString())

  // Global Function -- add to search Params and remove from search params if id is exist
  const handleWithSearchParams = (type: string, id: string) => {    
    if (searchParams.get(type)) {
      const ids = searchParams.get(type)?.split(',').map((id:any) => id.trim());
      if (ids?.includes(id.toString())) {
        ids?.splice(ids.indexOf(id.toString()), 1);
        setSearchParams({...extractExistingParams(searchParams), [type]: ids?.join(',')});
      } else {
        ids?.push(id.toString());
        if (ids) {
          setSearchParams({...extractExistingParams(searchParams), [type]: ids.join(',')});
        }
      }

    } else {
      setSearchParams({...extractExistingParams(searchParams), [type]: id})
    }

  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          padding: "0 0px",
          mb: "8px",
          cursor:"pointer"
        }}
        onClick={() => {
          handleWithSearchParams(type, id)
        }}
      >
        <CheckBoxCheck className={`${isCheckedParams && "container main-outline-btn"}`}>
          {isCheckedParams && (
            <span className="main-text">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="8"
                viewBox="0 0 10 8"
                fill="none"
              >
                <path
                  d="M8.75 0.875L3.5 7.125L1.25 4.625"
                  stroke="url(#paint0_linear_57_2520)"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_57_2520"
                    x1="1.25"
                    y1="3.93056"
                    x2="8.74944"
                    y2="3.99523"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F5841F" />
                    <stop offset="1" stopColor="#ED1C24" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
          )}
        </CheckBoxCheck>
        <span>{name}</span>
      </Box>
    </>
  );
};

export default CustomCheck;
