import React from "react";
import { Box, Typography } from "@mui/material";
import { MainButton } from "../../style/style";
import PasswordField from "../common/inputs/passwordField";
import PhoneField from "../common/inputs/phoneField";
import ControlField from "../common/inputs/controlField";
import { setSignType } from "../../store/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { colors } from "../../utils/theme";


import { useFormik } from "formik";
import * as Yup from "yup";
import { RequestPostRegister } from "../../store/user/user";
import { t } from "i18next";

import CheckboxField from "../common/checkbox/checkbox";
import { toast } from "react-toastify";

const Sign = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, errorMsg, errorSignUp } = useSelector(
    (state: RootState) => state.user
  );
  
  const [checked, setChecked] = React.useState(true);

  const formik = useFormik({
    initialValues: {
      password: "",
      repassword: "",
      first_name: "",
      phone: "",
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required(`${t("Please first name")}`),
      phone: Yup.string()
        .required(`${t("Please enter a phone number")}`)
        .matches(
          /^[0-9]{10,11}$/,
          `${t("Phone number must be 10 or 11 numbers")}`
        ),
      password: Yup.string()
        .required(`${t("pleaseenterpassword")}`)
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)/,
          `${t("Password must contain at least one letter and one number")}`
        )
        .min(8, `${t("Password is too short - should be 8 chars minimum.")}`),
      repassword: Yup.string().test(
        "passwords-match",
        `${t("Passwords must match")}`,
        function (value) {
          return value === this.parent.password;
        }
      ),
    }),
    onSubmit: (values) => {
      const { password, first_name, phone } = values;
      if (checked) {

        dispatch(
          RequestPostRegister({
            password,
            first_name,
            phone,
          })
        ).then((req) => {
          {
            if (req.type == "RequestPostRegister/fulfilled") {
              dispatch(setSignType("verfiy"));
            }
          }
        });
      } else {
        toast.error(t('accept_policy'))
      }
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography sx={{ fontSize: "36px", fontWeight: "600" }}>
          {t("sign_up")}
        </Typography>
        <Typography sx={{ color: "#505050", mt: "8px", fontWeight: "400" }}>
          {t("create_your_accout")}
        </Typography>

        {/* <SocialSection /> */}

        <form onSubmit={formik.handleSubmit}>
          <Box mt="32px">
            <ControlField
              label={t("name")}
              id="first_name"
              name="first_name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.first_name}
              errors={
                formik.touched.first_name && formik.errors.first_name
                  ? formik.errors.first_name
                  : ""
              }
            />
          </Box>
          <Box mt="32px">
            <PhoneField
              label={t("mobile_number")}
              id="phone"
              name="phone"
              value={formik.values.phone}
              onChange={(e: any) => {
                formik.setFieldValue("phone", e);
              }}
              errors={
                formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : ""
              }
            />
          </Box>
          <Box mt="32px">
            <PasswordField
              name="password"
              label={t("password")}
              onChange={formik.handleChange}
              value={formik.values.password}
              errors={
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : ""
              }
            />
          </Box>

          <Box mt="32px">
            <PasswordField
              label={t("re_password")}
              name="repassword"
              onChange={formik.handleChange}
              value={formik.values.repassword}
              errors={
                formik.touched.repassword && formik.errors.repassword
                  ? formik.errors.repassword
                  : ""
              }
            />

            <Typography sx={{ color: "red", mt: "10px" }}>
              {errorSignUp && errorSignUp}
            </Typography>
          </Box>

          <button type="submit" style={{ display: "none" }}>
            test
          </button>
        </form>
        <Box sx={{ fontSize: "13px", fontWeight: "500", mt: "33px" }}>
        <CheckboxField onChange={(e:any) => setChecked(e.target.checked)} checked={checked}/>

          {t("agree_policy")}
        </Box>
      </Box>

      <Box sx={{ mt: "35px" }}>
        <MainButton
          sx={{ width: "100%" }}
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          {t("sign_up")}
        </MainButton>
        <Typography
          mt="14px"
          sx={{
            textAlign: "center",
            color: "rgba(0, 0, 0, 0.40) !important",
            fontWeight: "400",
          }}
          onClick={() => dispatch(setSignType("login"))}
        >
          {t("already_have_account")}{" "}
          <span style={{ color: colors.orange[100], cursor: "pointer" }}>
            {t("login")}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default Sign;
