import { Breakpoints, createTheme, ThemeOptions } from "@mui/material/styles";
import { selectLanguage } from "../store/globalSlice";
// import library from "@mui/material/styles"

export const colors = {
  dark: {
    100: "#1B1B1B",
  },
  red: {
    100: "#ED1C24",
    200: "#EE2E36",
    300: "#F04047",
    400: "#F15359",
    500: "#F3656A",
    600: "#F4777C",
    700: "#F6898D",
  },
  orange: {
    100: "#F5841F",
    200: "#F68E32",
    300: "#F79945",
    400: "#F8A357",
    500: "#F8AD6A",
    600: "#F9B87D",
    700: "#FAC290",
    800: "#FEF2E8"
  },
  blue: {
    100: "#1F90F5",
    200: "#359BF6",
    300: "#4BA6F7",
    400: "#62B1F8",
    500: "#78BCF9",
    600: "#8FC7FA",
    700: "#A5D2FB",
  },
  card: {
    100: "rgba(251, 251, 251, 1)"
  }
};

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
      dark: string;
      muted: string;
      primary: string;
      footerBg: string;
      card: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    // status?: {
    //   danger?: string;
    //   dark: string;
    //   muted: string;
    //   primary: string;
    //   footerBg: string;
    //   card: string;
    // };
    breakpoints?: {
      values: {
        xs: number;
        sm: number;
        md: number;
        lg: number;
        xl: number;
      };
      down?: (key: keyof Breakpoints["values"]) => string;
      up?: (key: keyof Breakpoints["values"]) => string;
      between?: (
        start: keyof Breakpoints["values"],
        end: keyof Breakpoints["values"]
      ) => string;
    };

  }
}
const lang = localStorage.getItem('lang')

export const theme: ThemeOptions = createTheme({
  direction: lang === "en" ? 'ltr' : 'rtl',
  palette: {
    primary: {
      main: colors.red[700],
      light: "white",
    },
  },
  typography: {
    fontFamily: "'cairo', sans-serif",
  },
  // components: {
  //   MuiContainer: {
  //     styleOverrides: {
  //       maxWidthLg: {
  //         maxWidth: "90% !important",
  //       },
  //     },
  //   },
  // },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },  

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          html: {
            direction: (theme:any) => {
              const selectedLanguage = selectLanguage(theme);

              return selectedLanguage === 'ar' ? 'rtl' : 'ltr';
            },
          },
        },
      },
    },
  },
 
});
