// import { BASEURL } from '@/data/APIS';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import type { PayloadAction } from "@reduxjs/toolkit";

import { BASEURL } from "../../data/apis";
import customFetch from "../../utils/axios";
import { ProductProps } from "../../types/product";
import { setPaymentLoading } from "../globalSlice";

export interface CheckoutState {
  loading: boolean;
  error: boolean;
  checkout: any;
  errorMsg: String;
}

const initialState: CheckoutState = {
  loading: false,
  checkout: [],
  error: false,
  errorMsg: "",
};

export const RequestPostCheckout = createAsyncThunk(
  "RequestPostCheckout",
  async (data:any, { getState, rejectWithValue, dispatch }) => {
    const state: any = getState();
    const token: any = state.user.user;

    try {
      const response = await axios.post(
        `${BASEURL}/cat/checkout/`,
        data,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
        );

        return response.data;
    } catch (error: any) {

      return rejectWithValue(error.data);
    }
  }
);

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(RequestPostCheckout.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.checkout = [];
      })
      .addCase(RequestPostCheckout.fulfilled, (state, action) => {
        state.loading = false;
        state.checkout = action.payload;
      })
      .addCase(RequestPostCheckout.rejected, (state, action: any) => {
        state.loading = false;
        state.error = false;
      });
  },
});

// Action creators are generated for each case reducer function
export default checkoutSlice.reducer;
