import { useEffect } from "react";
import Slider from "react-slick";
import Box from "@mui/material/Box/Box";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { RequestGetAds } from "../../store/ads/adsSlice";

const MainSlider = ({ sliderInfo }: any) => {
  // ---------- SELECTOR ----------
  const { ads, loading } = useSelector((state: RootState) => state.ads)
  // ---------- DISPATCH ----------
  const dispatch = useDispatch<AppDispatch>()
  // ---------- CONSTANTS ----------
  const settings = {
    dots: true,
    infinite: true,
    speed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    swipeToSlide: true,
    autoplay: true,
    appendDots: (dots: any) => <ul>{dots}</ul>,
    customPaging: (i: number) => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    )
  };

  // ---------- ONCE CHANGE ----------
  useEffect(() => {
    dispatch(RequestGetAds())
  }, [])

  return (
    <Box mt="24px" >
      <Slider {...settings}>
        {loading ?
          <Box sx={{ height: '288px', width: '100%' }}>
            <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
          </Box> :
          ads.map((photo: { image_ad: string; name: string }, index: any) => {
            return (
              <Box key={index} sx={{  width: '100%' }}>
                <img
                  src={photo.image_ad}
                  alt={photo.name}
                  style={{ width: "100%", height: '100%', 
                   }}
                  key={index}
                />
              </Box>
            );
          })}
      </Slider>
    </Box>
  );
};

export default MainSlider;
