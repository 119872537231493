import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useEffect } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import i18next, { t } from "i18next";
interface Propslist {
  onClick?: () => void;
  open?: boolean;
  primary: string;
  icon: React.ReactNode;
  listofprimary?: propslist[];
  type?: boolean
}
interface propslist {
  name: string;
  link: string;
  onclick: () => void;
  type?: boolean
}

function Listtoresponce({
  onClick,
  open,
  type=true,
  primary,
  icon,
  listofprimary,
}: Propslist) {
  const [opentrue, setOpen] = React.useState(false);

  const handleClicklist = () => {
    setOpen(!opentrue);
  };
  useEffect(() => { }, [opentrue]);

  return (
    <>
      {
        <>
          <ListItemButton
            onClick={handleClicklist}
            sx={{ width: { md: "47vw", xs: "100%", textAlign: i18next.language == "en" ? "left" : 'right' } }}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={t(primary)} />
            {type && opentrue ? <ExpandLess /> : type && !opentrue ? <ExpandMore /> : ""}
          </ListItemButton>
          <Collapse in={opentrue} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {listofprimary?.map((ele , index) => (
                <ListItemButton key={index} sx={{ pl: i18next.language == "en" ? 4 : 0, textAlign: i18next.language == "en" ? "left" : 'right', pr: i18next.language == "ar" ? 4 : 0 }} onClick={ele.onclick}>
                  <ListItemText primary={t(ele.name)} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </>
      }
    </>
  );
}

export default Listtoresponce;
