import React from "react";
import ProductCart from "./productCart";
import { Box, Typography } from "@mui/material";
import { MainButton } from "../../style/style";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { t } from "i18next";
import { setOpenDrawerCart } from "../../store/globalSlice";

const SideCartProducts = ({ items, total_products_price }: any) => {
  // const items = useSelector((state: RootState) => state.cart.items);
  // const total_products_price = useSelector(
  //   (state: RootState) => state.cart.cartUser.total_products_price
  // );
  const dispatch = useDispatch<AppDispatch>();
  console.log("items", items);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        // height: "calc(100% - 120px)", // add it if not res
        height:"100%", // remove it if not responsive
        //  mb: "50px",
        mt: { sm: 0, xs: "30px" },
        // overflow:"hidden" TEST IT AGAIN
      }}
    >
      <Box sx={{}}>
        {/* // overflow:"hidden" TEST IT AGAIN */}
        {items?.map((item: any , index : number) => {
          return (
            <Box mb="15px" key={index}>
              <ProductCart item={item} isDelete={true} isQty={true}/>
            </Box>
          );
        })}
      </Box>
      <Box sx={{ position: "sticky", bottom: "0", background: "#FFF" }}>
        <Box>
          <Box
            sx={{
              width: "100%",
              background: "rgba(80, 80, 80, 0.50)",
              height: "1px",
            }}
          ></Box>
          <Box sx={{ my: "16px" }}>
            <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
              {t("total")} : {total_products_price} {t("egp")}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            flexWrap: "wrap",
            flexDirection: { sm: "row", xs: "column" },
            mb: "24px",
          }}
        >
          <Box
            sx={{ flex: 1 }}
            onClick={() => {
              dispatch(setOpenDrawerCart(false));
            }}
          >
            <Link to="/cart" style={{ textDecoration: "none" }}>
              <button
                className="main-outline-btn main-text"
                style={{ width: "100%", cursor: "pointer" }}
              >
                {t("viewcart")}
              </button>
            </Link>
          </Box>
          <Box
            sx={{ flex: 1 }}
            onClick={() => dispatch(setOpenDrawerCart(false))}
          >
            <Link to="/checkout" style={{ textDecoration: "none" }}>
              <MainButton sx={{ width: "100%" }}>{t("checkout")}</MainButton>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SideCartProducts;
