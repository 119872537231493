import {useDispatch} from "react-redux";
import {AppDispatch} from "../store/store";
import {RequestWishList,} from "../store/wishlist/wishlistSlice";

function useCustomHooks() {
    const dispatch = useDispatch<AppDispatch>();

    const handleConsole = (id: number, is_wishList: boolean) => {
        if (is_wishList) {
            dispatch(RequestWishList(id));
        }else
        {
            dispatch(RequestWishList(id));
        }
    }
    return handleConsole;
}

export default useCustomHooks;
