import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { useCallback } from "react";
import {
  RequestWishList
} from "../store/wishlist/wishlistSlice";
import { RequestDeleteItem, RequestPostToCart, RequestUpdateItem } from "../store/cart/cartSlice";
import {
  RequestDeleteGuestItem,
  RequestPostToGuestCart,
  RequestUpdateGuestItem,
} from "../store/guestCart/guestCart";

function useUpdateItem() {
  const dispatch = useDispatch<AppDispatch>();

  // token
  const user = useSelector((state: RootState) => state.user.user);
  const guestUser = useSelector((state: RootState) => state.guestCart.cartID);



  const handleUpdateItem = (id:any, quantityItem:any ) => {
    if (user !== null ) {
      dispatch(RequestUpdateItem({id, quantity:quantityItem}))
    } else  {
      dispatch(RequestUpdateGuestItem({id, quantity:quantityItem}))
    }
  }

  return handleUpdateItem;
}

export default useUpdateItem;