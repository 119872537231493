import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import React from "react";

const CheckoutPrice = ({cartUser}:any) => {
  return (
    <Box>
      <Box mt="32px">
        <Typography
          sx={{
            fontSize: { xs: "16px", md: "18px" },
            fontWeight: "500",
            mb: "10px",
            color: "#505050",
          }}
        >
          {t('shipping')} : {cartUser?.shipping_price} {t('egp')}
        </Typography>

        <Typography
          sx={{
            fontSize: { xs: "16px", md: "18px" },
            fontWeight: "500",
            mb: "10px",
            color: "#505050",
          }}
        >
          {t('products')} : {cartUser?.total_products_price} {t('egp')}
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          background: "#50505080",
          height: "1px",
          mt: "16px",
        }}
      ></Box>

      <Typography
        sx={{
          fontSize: { xs: "14px", sm: "16px", md: "20px" },
          fontWeight: "600",
          mt: "16px",
        }}
      >
        {t('total')} : {cartUser?.delivery_total_price} {t('egp')}
      </Typography>
    </Box>
  );
};

export default CheckoutPrice;
