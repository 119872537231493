import { useEffect } from "react";
import { Box, Skeleton } from "@mui/material";
import { H2 } from "../../style/style";
import rafalImage from "../../assets/vedio-review/review-2.png";
import Slider from "react-slick";
import VideoCard from "./videoCard";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { RequestVideoReviews } from "../../store/video/videoSlice";
import { VideoProps } from "../../types/video";
const VideoPerview = () => {
  // ---------- SELECTOR ----------
  const videos = useSelector((state: RootState) => state.video.video);
  const loading = useSelector((state: RootState) => state.video.loading);
  const lang = useSelector((state: RootState) => state.global.mainLang);

  // ---------- DISPATCH ----------
  const dispatch = useDispatch<AppDispatch>();

  // ---------- CONSTANT ----------
  var settings = {
    className: "serv",
    speed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    infinite: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  // ---------- ONCE CHANGE ----------
  useEffect(() => {
    dispatch(RequestVideoReviews());
  }, []);

  return (
    <Box mt="48px" pb="48px">
      <H2 sx={{ mb: { xs: "24px", md: "24px" } }}>{t("Video review")}</H2>

      <Box
        sx={{
          positon: "relative",
          left: "126px",
          // marginTop: "32px",
          ".serv .slick-list": { padding: "10px 0" },
        }}
      >
        {loading ? (
          <Slider {...settings}>
            {Array.from(new Array(12)).map((item , index) => {
              return (
                <Box key={index}>
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={250}
                    sx={{
                      textAlign: "center",
                      display: "block",
                      margin: "auto",
                    }}
                  />
                </Box>
              );
            })}
          </Slider>
        ) : (
          <Slider {...settings}>
            {videos?.map((video: VideoProps) => {
              return <VideoCard video={video} />;
            })}
          </Slider>
        )}

        <Box
          sx={{
            position: "absolute",
            zIndex: "-1",
            marginTop: "-87px",
            left: lang === "en" ? "auto" : "0",
            right: lang === "ar" ? "auto" : "0"
          }}
        >
          <img src={rafalImage} alt="rafal" style={{ width: "100%" }} />
        </Box>
      </Box>
    </Box>
  );
};

export default VideoPerview;
