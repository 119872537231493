import React from "react";
import { OutlinedInput, Typography } from "@mui/material";

interface ControlProps {
  id?: string;
  type?: string;
  label?: string;
  sx?: {};
  name?: string;
  value?: any;
  onChange?: any;
  ariaLabel?:string;
  errors?:any;
  errorMsg?: string;
}
const ControlField = ({
  type = "text",
  label,
  sx,
  value,
  name,
  id,
  onChange,
  ariaLabel,
  errors,
  errorMsg
}: ControlProps) => {

  const isTextType = type === "text";
  const isNumberType = type === "number";

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (isTextType) {
      // Allow only characters (letters and spaces)
      const sanitizedValue =  inputValue.replace(/[^A-Za-z\s\u0600-\u06FF]/g, "");
      event.target.value = sanitizedValue;
    }

    if (isNumberType) {
      // Allow only numbers (0-9)
      // const sanitizedValue = inputValue.replace(/[^0-9]|^0+(?!$)/g, "");
      const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
      event.target.value = sanitizedValue;
    }

    onChange(event);
  };

  return (
    <>
      {label && (
        <Typography sx={{ fontSize: "16px", fontWeight: "500", mb: "8px", textTransform:"capitalize" }}>
          {label} 
        </Typography>
      )}
      <OutlinedInput
        id={id}
        type={type}
        fullWidth
        label=""
        value={value}
        name={name}
        onChange={handleInputChange}
        aria-label={ariaLabel}
        sx={{
          "&.MuiInputBase-root": {
            background: "#F2F2F2",
            borderRadius: "12px",
            borderColor: "#F2F2F2",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },

          "&.MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused.MuiOutlinedInput-root.MuiInputBase-root": {
            background: "#fff",
            boxShadow:
              "2px 2px 10px 0px rgba(237, 28, 36, 0.16), -2px -2px 10px 0px rgba(237, 28, 36, 0.16)",
          },
          ...sx,
        }}
      />
      <Typography component="span" sx={{color:"red", mt:"5px"}}>{errors}</Typography>
    </>
  );
};

export default ControlField;
