import React from "react";
import { Link, useLocation } from "react-router-dom";
import Box from "@mui/material/Box/Box";
import i18next from "i18next";
import { rafalArrowIcon } from "../../assets/svg/svg";

interface ProfileTabProps {
  text?: string;
  path?: any;
}

const activeAccountIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.42899 18.8578L16.2861 12.0007L9.42899 5.14355"
      stroke="url(#paint0_linear_467_16999)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_467_16999"
        x1="12.9338"
        y1="5.14355"
        x2="12.7367"
        y2="18.855"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5841F" />
        <stop offset="1" stopColor="#ED1C24" />
      </linearGradient>
    </defs>
  </svg>
);

const ProfileTab = ({ text, path }: ProfileTabProps) => {
  const location = useLocation();
  const pathname = location.pathname;
  const pathArray = pathname.split("/");
  const checkPath = pathArray[pathArray.length - 1];

  return (
    <Box>
      <Link to={`/profile/${path === "/profile" ? "" : path}`} style={{textDecoration: "none"}}>
        <p
          className={
            path.split("/").includes(checkPath)
              ? "main-text main-outline-btn w-100 text-align-inherit flex-between"
              : "list-profile-bg"
          }
          style={{ color: "#000" }}
        >
          {text}

          {path.split("/").includes(checkPath) ? i18next.language==="en"? activeAccountIcon :rafalArrowIcon: ""}
        </p>
      </Link>
    </Box>
  );
};

export default ProfileTab;
