import styled from "@emotion/styled";

import { Button, TextField, Typography, Select, Box } from "@mui/material";

export const H2 = styled(Typography)({
  color: "#1B1B1B",
  fontFamily: "Montserrat",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
});

export const MainButton = styled(Button)({
  background: "linear-gradient(90deg, #F5841F 0%, #ED1C24 100%)",
  color: "rgba(255, 255, 255, 1)",
  borderRadius: "8px",
  padding: "12px 50px", 
  border: "none",
  cursor: "pointer",
  fontSize: "18px",
  fontWeight: "600",
  textTransform: "capitalize",
});

export const FacebookButton = styled("button")(({ theme }) => ({
  background: "transparent",
  border: "1px solid #D9D9D9",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  padding: "15px 50px",
  gap: "12px",
  fontSize: "16px",
  fontWeight: "600",
  "&:hover": {
    backgroundColor: "#3B5998",
    color: "#fff",
  },
}));

export const DarkButton = styled(Button)({
  background: "#fff",
  color: "#000",
  borderRadius: "8px",
  padding: "10px 50px", // 15px 50px
  cursor: "pointer",
  fontSize: "18px",
  fontWeight: "600",
  textTransform: "capitalize",
  border: "2px solid rgba(80, 80, 80, 1)",
  "&:hover": {
    background: "#fff",
  },
});

export const IconBox = styled(Box)({
  width: "32px",
  height: "32px",
  background: "#fff",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor:"pointer"
});

export const ProfileList = styled(Typography)(({ theme }) => ({
  background: "#FAFAFA",
  fontSize: "18px",
  fontWeight: "600",
  padding: "18px",
  borderRadius: "8px",
  marginBottom:"16px"
}));


