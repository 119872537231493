import { Box } from '@mui/material';

import AccordionRate from '../shop/accordionRate';
import AccordionCheck from '../shop/accordionCheck';
import AccordionContent from '../shop/accordionContent';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Accordion from './accordion';
import {  useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { CheckBoxCheck } from '../common/customCheck';
import { extractExistingParams } from '../../utils/helper-function';

const ShopFilterParams = () => {
    // STATE & PARAMS
    const [searchParams, setSearchParams] = useSearchParams();
    const { t, } = useTranslation();

    // ------ SELECTOR --------
    const { cate, filter } = useSelector(
        (state: RootState) => state.products
    );
    const { color } = useSelector((state: RootState) => state.color);

    return (
        <>
            {/* ------- PRICE ---------*/}
            <Accordion
                text={t("price")}
                content={<AccordionContent />}
            />
            <Box
                sx={{ width: "100%", height: "1px", background: "#DFDFDF" }}
            ></Box>

            {/* ------- COLOR ---------*/}
            <Accordion
                text={t("color")}
                content={
                    <AccordionCheck
                        items={color}
                        filter={filter}
                        type="color_ids"
                    />
                }
            />
            <Box
                sx={{ width: "100%", height: "1px", background: "#DFDFDF" }}
            ></Box>

            {/* ------- RATE ---------*/}
            <Accordion
                text={t("rate")}
                content={<AccordionRate type="avg_rating" filter={filter} />}
            />

            <Box
                sx={{ width: "100%", height: "1px", background: "#DFDFDF" }}
            ></Box>

            {/* ------- CATEGORIES ---------*/}
            <Accordion
                text={t("categories")}
                content={
                    <AccordionCheck
                        items={cate}
                        type="category_ids"
                        filter={filter}
                    />
                }
            />
            <Box
                sx={{ width: "100%", height: "1px", background: "#DFDFDF" }}
            ></Box>

            {/* ------- VIEDO REVIEW ---------*/}
            <Accordion
                text={t("video review")}
                content={
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                                padding: "0 10px",
                                mb: "8px",
                            }}
                            onClick={() => {
                                setSearchParams({ ...extractExistingParams(searchParams), video_review: "true" });
                            }}
                        >
                            <CheckBoxCheck
                                className={`${searchParams.get("video_review") !== "false"
                                    ? "container main-outline-btn"
                                    : ""
                                    }`}
                            >
                                {searchParams.get("video_review") !== "false" && (
                                    <span className="main-text">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="8"
                                            viewBox="0 0 10 8"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.75 0.875L3.5 7.125L1.25 4.625"
                                                stroke="url(#paint0_linear_57_2520)"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <defs>
                                                <linearGradient
                                                    id="paint0_linear_57_2520"
                                                    x1="1.25"
                                                    y1="3.93056"
                                                    x2="8.74944"
                                                    y2="3.99523"
                                                    gradientUnits="userSpaceOnUse"
                                                >
                                                    <stop stopColor="#F5841F" />
                                                    <stop offset="1" stopColor="#ED1C24" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                )}
                            </CheckBoxCheck>
                            <span>true</span>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                                padding: "0 10px",
                                mb: "15px",
                            }}
                            onClick={() => {
                                setSearchParams({ ...extractExistingParams(searchParams), video_review: "false" });
                            }}
                        >
                            <CheckBoxCheck
                                className={`${searchParams.get("video_review") === "false"
                                    ? "container main-outline-btn"
                                    : ""
                                    }`}
                            >
                                {searchParams.get("video_review") === "false" && (
                                    <span className="main-text">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="8"
                                            viewBox="0 0 10 8"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.75 0.875L3.5 7.125L1.25 4.625"
                                                stroke="url(#paint0_linear_57_2520)"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <defs>
                                                <linearGradient
                                                    id="paint0_linear_57_2520"
                                                    x1="1.25"
                                                    y1="3.93056"
                                                    x2="8.74944"
                                                    y2="3.99523"
                                                    gradientUnits="userSpaceOnUse"
                                                >
                                                    <stop stopColor="#F5841F" />
                                                    <stop offset="1" stopColor="#ED1C24" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                )}
                            </CheckBoxCheck>
                            <span>false</span>
                        </Box>
                    </>
                }
            />
        </>
    )
}

export default ShopFilterParams
