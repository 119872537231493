import React from "react";
import ProfileLayout from "../../components/profile/profileLayout";
import HeroHeading from "../../components/common/HeroHeading";
import { Box, Typography } from "@mui/material";
import { t } from "i18next";

const Privacy = () => {
  const crumbs = [
    { label:` ${t("Home")}`, link: "/", active: false },
    { label:`${t('privacy')}`, link: `/profile/privacy`, active: true },
  ];
  return (
    <ProfileLayout crumbs={crumbs}>
      <HeroHeading heading={t('Privacy policy For Rafal')} />

      <Box sx={{ fontSize: "18px", fontWeight: "600 !important",p:2,wordSpacing:"2px",whiteSpace:"break-spaces" ,wordBreak:"break-all"}}>
        <Typography mb="16px" fontSize="18px" fontWeight={500}>
         {t('textprivacy1')}
        </Typography>
        {/* <Typography mb="16px" fontSize="18px" fontWeight={500}>
          {t('textprivacy2')}
        </Typography>
        <Typography mb="16px" fontSize="18px" fontWeight={500}>
          {t('textprivacy3')}
        </Typography> */}
        {/* <Typography mb="16px" fontSize="18px" fontWeight={600}>
          {t('consent')}
        </Typography> */}
        {/* <Typography mb="16px" fontSize="18px" fontWeight={500}>
         {t('textprivacy4')}
        </Typography> */}
        <Typography mb="16px" fontSize="18px" fontWeight={600}>
          {t('textprivacy5')}
        </Typography>
        <Typography mb="16px" fontSize="18px" fontWeight={500}>
          {t('textprivacy6')}
        </Typography>
        <Typography mb="16px" fontSize="18px" fontWeight={500}>
          {t('textprivacy7')}
        </Typography>
        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy8')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={600}>
        {t('textprivacy9')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
         {t('textprivacy10')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
          <ul style={{ listStyle: "type", listStyleType: "disc" }}>
            <li>{t('text1')}</li>
            <li>{t('text2')}</li>
            <li>{t('text3')}</li>

            <li>{t('text4')}</li>
            <li>
              {t('text5')}
            </li>
            <li>{t('text6')}</li>
            <li>{t('text7')}</li>
          </ul>
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={600}>
           {t('textprivacy11')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy12')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={600}>
        {t('textprivacy13')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
          {t('textprivacy14')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={600}>
        {t('textprivacy15')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy16')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy17')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy18')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={600}>
        {t('textprivacy19')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy20')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy21')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy22')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy23')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy24')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy25')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy26')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy27')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={600}>
        {t('textprivacy28')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy29')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy30')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy31')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy32')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy33')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy34')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy35')}
        </Typography>

        {/* <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy35')}
        </Typography> */}

        <Typography mb="16px" fontSize="18px" fontWeight={600}>
        {t('textprivacy36')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy37')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy38')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={600}>
        {t('textprivacy39')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy40')}
        </Typography>

        <Typography mb="16px" fontSize="18px" fontWeight={500}>
        {t('textprivacy41')}
        </Typography>
      </Box>
    </ProfileLayout>
  );
};

export default Privacy;
