import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import personImage from "../../assets/product/person.png";
// import {HiOutlinePhoto} from 'react-icons/hi'
import { MdOutlineInsertPhoto } from "react-icons/md";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { DarkButton, MainButton } from "../../style/style";
import Moment from "react-moment";
import Rating from "@mui/material/Rating";
import styled from "@emotion/styled";
import moment from "moment";
import { useEffect } from "react";

export const ReviewName = styled(Box)({
  width: "48px",
  height: "48px",
  borderRadius: "50%",
  background: "rgba(246, 137, 141, 0.50)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "uppercase",
  fontWeight: "600",
  fontSize: "28px",
  color: "red",
});

const ReviewComment = ({ review, key, length, index }: any) => {
  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "rgba(240, 194, 77, 1)",
    },
    "& .MuiRating-iconHover": {
      color: "rgba(240, 194, 77, 1)",
    },
  });
  return (
    <Box mt="27px">
      <Box>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "13px" }}>
            {/* <img src={personImage} alt="test" /> */}
            <ReviewName>
              {review?.full_name[0] ? review?.full_name[0] : ""}
            </ReviewName>
            <Box>
              <Typography>{review?.full_name}</Typography>

              <StyledRating
                name="half-rating"
                defaultValue={review?.rating}
                precision={0.5}
                readOnly
              />
              {/* <AiFillStar style={{ color: "rgba(240, 194, 77, 1)" }} />
              <AiFillStar style={{ color: "rgba(240, 194, 77, 1)" }} />
              <AiFillStar style={{ color: "rgba(240, 194, 77, 1)" }} />
              <AiFillStar style={{ color: "rgba(240, 194, 77, 1)" }} />
              <AiOutlineStar style={{ color: "rgba(240, 194, 77, 1)" }} /> */}
            </Box>
          </Box>
          {/* profile */}
          {/* <Box> */}
          {/* <MdOutlineInsertPhoto style={{ fontSize: "20px" }} /> */}
          {/* <BiDotsHorizontalRounded style={{ fontSize: "20px" }} /> */}
          {/* </Box> */}
        </Stack>
        <Box
          mt="19px"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography sx={{ mb: "8px" }}>{review?.description}</Typography>
            <span
              style={{
                color: "rgba(80, 80, 80, 1)",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              {moment().format("MMM Do YY") ===
              moment(review?.created_at).format("MMM Do YY")
                ? moment(review?.created_at).startOf("hour").fromNow()
                : moment(review?.created_at).format("MMMM Do YYYY, h:mm:ss a")}
            </span>
          </Box>
          <Box
            sx={{
              width: "10%",
              img: {
                width: "100%",
              },
            }}
          >
            <img
              src={review?.product_review_image}
              alt={review?.product_review_image}
            />
          </Box>
        </Box>
      </Box>
      {index !== length - 1 ? (
        <Box
          sx={{
            width: "100%",
            height: "1px",
            background: "rgba(223, 223, 223, 1)",
            mt: "16px",
          }}
        ></Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default ReviewComment;
